.teaser-portal {
    background-color: #fff;
    height: 100%;
    padding: 45px;
    position: relative;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 767px) {
    .teaser-portal {
        padding: 15px 20px;
        font-size: 14px;
    }
    .teaser-portal__headline {
        font-size: 20px;
    }
}

.teaser-portal__badge {
    position: absolute;
    top: 20px;
    right: 20px;
}

.teaser-portal__headline {
    font-size: 32px;
    color: var(--color-darkgreen);
    font-family: var(--font-extrabold);
}

.teaser-portal__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
}

.max-width-600 {
    max-width: 600px;
}

.teaser-portal__button{
    max-width: fit-content;
}