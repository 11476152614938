.faq-accordion {
    margin-bottom: 1rem;
}
.faq-accordion__heading {
    background-color: #F5F0E8;
    color: #004220;
    line-height: 1;
}
.faq-accordion__link{
    color: #004220;
    font-size: 2rem;
    font-family: var(--font-bold);
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.faq-accordion__link:focus{
    text-decoration: none;
    color: #004220;
}
.faq-accordion__title {
    padding: 2rem;
    margin: 0;
}
.faq-accordion__heading:hover {
    background-color: #a2c614;
}
.faq-accordion__heading:hover .faq-accordion__link {
     text-decoration: none;
     color: #fff!important;
}
.faq-accordion__heading:hover .faq-accordion__btn {
    color: #fff!important;
}
.faq-accordion__content {
    margin-top: 0.5rem;
    border: 2px solid #f5f0e8;
}
.faq-accordion__body{
    padding: 2rem;
}
.faq-accordion__link .faq-accordion__btn{
    transition: all .25s ease;
}
.faq-accordion__link.collapsed .faq-accordion__btn{
    transition: all .25s ease;
    transform: rotate(45deg);

}