
/** ------- MAP ------------------------------------------------------------------------------------------------- **/
.main-content #container-map, #map-container {
    min-height: 360px;
    display: block;
    position: relative;
    background-color:transparent;
}
#container-map {
    height:auto;
}
.fullscreen-map #container-map {
    height:100%;
}
.map-fullscreen, .static-map-fullscreen {
    position:absolute;
    bottom:0;
    right:15px;
    z-index:3;
    color:#fff;
    font-size:35px;
    background-color: rgba(0, 66, 32, 0.86);
    padding:10px;
    cursor: pointer;
}
.trade-graph + .static-map-fullscreen {
    right:0;
}

.static-map-fullscreen:hover {
    color:#fff;
    text-decoration: none;
}
.map-minus, .map-plus {
    position:absolute;
    top:0;
    right:15px;
    z-index:3;
    color:#fff;
    font-size:35px;
    background-color: rgba(0, 66, 32, 0.86);
    padding:10px;
    cursor: pointer;
}
.map-minus {
    top:60px;
}
.mfp-map.mfp-wrap.mfp-ready .mfp-content {
    height:100%;
}
.mfp-map+.mfp-wrap .mfp-close-btn-in .mfp-close {
    color: var(--color-darkgreen);
}

.google-map {
    min-height: 180px;
}
.js-google-map__canvas {
    height: 360px;
}
.mfp-container .js-google-map__canvas {
    height:100%;
}
.fullscreen-map {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1100;
    overflow:hidden;

}
.fullscreen-map .js-google-map__canvas {
    height:100%;
}
.map-minus, .map-plus {
    display:none;
}
.fullscreen-map .map-minus, .fullscreen-map .map-plus {
    display:block;
    right:0;
}

.map-close  {
    display:none;
    position: absolute;
    bottom: 0;
    right: 15px;
    z-index: 3;
    color: #fff;
    font-size: 35px;
    background-color: rgba(0,66,32,.86);
    padding: 10px;
    cursor: pointer;
}
.fullscreen-map .map-close {
    display:block;
    right:0;
}
.fullscreen-map .map-fullscreen {
    display:none;
}

.infobox-content {
    background-color:#fff;
    padding:20px;
    color: var(--color-darkgreen);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.32);
}
.infobox-content a {
    color: var(--color-darkgreen);
}
.infobox-content p {
    margin-bottom:0px;
}
.infobox-address {
    margin-top:10px;
}

.infobox-content:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border-style: solid;
    border-color: #fff transparent;
    width: 0;
    height: 0;
    display: block;
    border-width: 10px 15px 0;
    z-index: 2;
}