/** ------- HEADER & NAVIGATION ---------------------------------------------------------------------------------------- **/

.navbar {
    height:130px;
    margin-bottom:0px;
}
.navbar-nav {
    height:100%;
    padding-left:20px;
}
.navbar-nav a span {
    display:block;
}
.navbar-nav a span:first-child {
    color:var(--color-darkgreen);
    font-size:24px;
    font-family:var(--font-bold);
}
.navbar-nav a span:nth-child(2) {
    color:var(--color-darkgreen);
    font-size:24px;
    font-family:var(--font-bold);
    line-height:24px;

}
.navbar .navbar-nav > li > a {
    padding: 22px 26px;
}
.navbar label {
    display:block;
    color:var(--color-darkgreen);
    text-transform: uppercase;
    font-size:20px;
    font-family: var(--font-bold);
}

.nav-form .form-group {
    position:relative;
}
.nav-form.navbar-right {
    padding-left:30px;
    margin-right:0px;
    background-color: #F5F0E8;
}
.nav-form .form-control {
    width:280px;
    height:45px;
}
.search-form .icon-search{
    position:absolute;
    left:0;
    padding: 0px 15px;
    font-size:22px;
    line-height:45px;
    color: var(--color-darkgreen);
}
.form-group.search-form input {
    padding-left:50px;
    border: none;
    background-color: #F5F0E8;
}
.form-group.search-form input::placeholder {
    color: var(--color-darkgreen);
}

.sticky-logo {
    float:left;
    padding:15px 0px;
    opacity:0;
}

.product-tab.affix .sticky-logo {
    opacity:1;
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    /*animation: stickyFrames linear 2s;*/
    /*animation-iteration-count: 1;*/
    /*transform-origin: 50% 50%;*/
    /*-webkit-animation: stickyFrames linear 2s;*/
    /*-webkit-animation-iteration-count: 1;*/
    /*-webkit-transform-origin: 50% 50%;*/
    /*-moz-animation: stickyFrames linear 2s;*/
    /*-moz-animation-iteration-count: 1;*/
    /*-moz-transform-origin: 50% 50%;*/
}

.product-tab.affix-top .sticky-logo {
    opacity:0;
    -moz-transform: scale(0.2);
    -webkit-transform: scale(0.2);
    -o-transform: scale(0.2);
    -ms-transform: scale(0.2);
    transform: scale(0.2);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

@media screen and (min-width: 768px) {
    .navbar-brand {
        height:auto;
        border-right: 1px solid #f3eee5;
    }
    .navbar-brand img {
        padding-right: 40px;
        padding-left: 20px;
    }
    .nav>li {
        position: static;
    }
    .navbar {
        position:static;
    }

    .nav > li span {
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        -webkit-backface-visibility: hidden;
    }

    .nav > li.is-open span,
    .nav > li:hover span{
        color: var(--color-lightgreen);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        -webkit-backface-visibility: hidden;
    }
    .nav > li > a span:nth-child(2){
        padding-bottom:15px;
        position: relative;
    }
    .nav > li > a span:nth-child(2):before {
        width: 100%;
        position: absolute;
        bottom: 15px;
        left: 0;
        height: 3px;
        content: '';
        display: block;
        background-color:var(--color-lightgreen);
        opacity:0;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        -webkit-backface-visibility: hidden;
    }

    .nav > li.is-open > a span:nth-child(2):before,
    .nav > li:hover span:nth-child(2):before {
        bottom: -3px;
        opacity:1;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        -webkit-backface-visibility: hidden;
    }

    .nav > li.has-dropdown > a span:nth-child(2):after {
        opacity:0;
        position: absolute;
        left: 50%;
        bottom: -25px;
        margin-left: -10px;
        content: '';
        border-top: 10px solid var(--color-lightgreen);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        z-index: 1500;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        -webkit-backface-visibility: hidden;
    }

    .nav > li.is-open > a span:nth-child(2):after,
    .nav > li.has-dropdown:hover > a span:nth-child(2):after {
        bottom:-13px;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        opacity:1;
        -webkit-backface-visibility: hidden;
    }
    .mainnav-dropdown-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        padding: 50px 20px;
        z-index: 250;
        background: #f3eee5;
        box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
        display: none;
        top: 130px;
        animation-duration: .25s;
        -webkit-animation-duration: .25s;
    }

    .is-open .mainnav-dropdown-wrapper {
        display: block;
    }

    .mainnav-dropdown-wrapper h4,
    .mainnav-dropdown-wrapper .h4{
        text-transform: uppercase;
        font-size:16px;
        font-family: var(--font-extrabold);
    }
    .mainnav-dropdown-wrapper ul {
        padding-left:10px;
    }
    .mainnav-dropdown-wrapper li > a {
        color:#000;
        font-size:16px;
        padding: 0px 10px;
    }
    .mainnav-dropdown-wrapper li {
        list-style: none;
        padding-bottom:15px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    .mainnav-dropdown-wrapper ul > li:before {
        content: "\E001";
        font-family: iconfont;
        padding-top: 3px;
        font-size:10px;
        color:#82807c;
        margin-left:10px;
    }
    .mainnav-dropdown-wrapper ul > ul li:before {
        display:none
    }
    .mainnav-dropdown-wrapper ul > ul li a {
        color:#82807c;
        margin-left:20px;
    }
    .mainnav-dropdown-image {
        width:100%;
        float:left;
    }
    .navbar__columns{
        display: flex;
        flex-direction: column;
    }
}

@media(max-width: 1440px) and (min-width: 768px){
    .navbar-brand img {
        padding-right:40px;
    }
    .navbar-nav {
        padding-left:0px;
    }
    .nav-form.navbar-right {
        width:auto;
        padding-left:10px;
    }
    .nav-form .form-control {
        width:auto;
    }

}

@media (max-width: 1279px) and (min-width: 768px){
    .nav-form .form-control {
        display:block;
    }
    .navbar-brand {
        padding-bottom: 25px;
    }
    .form-group label {
        padding:25px 15px;
        margin-left:10px;
    }
    .search-form .icon-search {
        float: left;
        top: 0;
    }
    .navbar-brand img {
        padding-right:20px;
        height:70px;
    }
    .navbar .navbar-nav>li>a {
        padding:13px 26px;
    }
    .nav-form.navbar-right {
        height: 45px;
    }
    .navbar {
        height:110px;
    }
    .mainnav-dropdown-wrapper {
        top: 110px;
    }
    .navbar-nav a span:first-child{
        font-size: 20px;
    }
    .nav>li>a span:nth-child(2) {
        font-size: 20px;
        padding-bottom: 4px;
    }
}

@media(max-width: 767px) {
    #page-wrapper.is-fixed {
        position:fixed;
        width:100%;
    }
    .navbar-brand img{
        height: 42px;
    }
    .snapjs-right .navbar-toggle {
        right:-7px;
    }
    .navbar-brand {
        padding: 4px 15px;
    }
    .navbar {
        top: 0;
        right: 0;
        width: 260px;
        background: #f3eee5;
        display: block;
        position: fixed;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100vh;
        transform: translateX(260px);
        -webkit-transform: translateX(260px);
        -moz-transform: translateX(260px);
        -o-transform: translateX(260px);
        -ms-transform: translateX(260px);
        transition: all .25s ease-out;
        z-index:1;
    }
    .navbar-header {
        height:70px;
        padding-top: 10px;
    }
    .navbar-nav > li {
        border-bottom: 1px solid #c2bfb8;
    }
    .navbar-nav {
        padding-left:0px;
        margin:0px 15px;
    }
    .form-group.search-form input {
        padding-left:20px;
    }
    .nav-form .form-control {
        background-color:transparent;
        border:none;
        color:var(--color-darkgreen);
        text-transform: uppercase;
        box-shadow:none;
        height:60px;
        padding-left:25px;
    }
    .navbar .navbar-nav > li > a {
        padding: 20px 15px;
    }
    .navbar-nav a span:first-child {
        font-size:18px;
        color: var(--color-darkgreen);
        font-family: var(--font-bold);
    }
    .navbar-nav a span:nth-child(2) {
        font-size:18px;
        color: var(--color-darkgreen);
        font-family: var(--font-bold);
    }
    .navbar-toggle {
        right:0;
        float:right;
        padding: 11px 9px;
        color: var(--color-darkgreen);
        font-size:20px;
        margin: 0;
    }
    .search-form {
        padding:0px;
        height:60px;
    }
    .search-form .icon-search {
        top:0;
        right:10px;
        left:inherit;
        text-align:right;
        line-height:60px;
    }
    .mainnav-dropdown-wrapper{
        display:none;
    }
    .navbar .has-dropdown__icon {
        position: absolute;
        top: 0;
        right: -10px;
        padding:0 25px;
        line-height: 85px;
        font-size:20px;
        cursor: pointer;
        transition: all .1s linear;

    }
    .navbar .is-open > .has-dropdown__icon {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        transition: all .1s linear;
    }
    .is-open .mainnav-dropdown-wrapper {
        display:block;
        padding-bottom:15px;
    }
    .mainnav-dropdown-image {
        display:none;
    }

    .mainnav-dropdown-wrapper ul {
        padding-left:0px;
    }
    .mainnav-dropdown-wrapper ul li {
        list-style: none;
        line-height: 40px;
        border-top: 1px solid #E1DCCF;
    }
    .mainnav-dropdown-wrapper ul li a {
        color: #524d46;
        font-family: var(--font-default);
    }
    .mainnav-dropdown-wrapper h4,
    .mainnav-dropdown-wrapper .h4 {
        border-top: 1px solid #E1DCCF;
        margin: 0;
        line-height: 40px;
    }
    .mainnav-dropdown-wrapper h4,
    .mainnav-dropdown-wrapper .h4,
    .mainnav-dropdown-wrapper .h4.has-dropdown a,
    .mainnav-dropdown-wrapper h4.has-dropdown a {
        color:var(--color-darkgreen);
        font-size: 16px;
    }

    .mainnav-dropdown-wrapper h4.has-dropdown + ul,
    .mainnav-dropdown-wrapper .h4.has-dropdown + ul{
        display:none;
    }
    .mainnav-dropdown-wrapper h4.has-dropdown.is-open +ul,
    .mainnav-dropdown-wrapper .h4.has-dropdown.is-open +ul{
        display:block;
    }
    .mainnav-dropdown-wrapper h4 > .has-dropdown__icon,
    .mainnav-dropdown-wrapper .h4 > .has-dropdown__icon{
        line-height:42px;
        font-size:17px;
    }
}

