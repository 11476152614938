/** ------- BASICS ----------------------------------------------------------------------------------------------------- **/
html {
    -ms-overflow-style: scrollbar;
}

body {
    font-size: 16px;
    margin: 0;
    padding: 0;
    position: relative;
    font-family: var(--font-light);
    color:#1f1d1a;
    max-width:1920px;
    margin: 0 auto;
}

.content-wrapper{
    overflow: hidden;
}

@media(max-width: 767px) {
    body {
        overflow-x:hidden;
    }
}

a .icon-arrow {
    padding-right:10px;
    color:#009d3d;
    font-size:12px;
}
a {
    color: #009d3d;
    text-decoration: none;
    font-size:16px;
}

a:hover {
    color: #009d3d;
    text-decoration: underline;
}

/*.bg-darkgreen a, #footer-wrapper a {*/
.bg-darkgreen a {
    color: var(--color-lightgreen);
}
/*.bg-darkgreen a:hover, #footer-wrapper a:hover {*/
.bg-darkgreen a:hover {
    color: var(--color-lightgreen);
}

b, strong {
    font-family: var(--font-bold);
}

p {
    font-size:16px;
}
.main-content ul {
    list-style: none;
    padding:0;
    margin:0;
}

.main-content ul:not(.pagination):not(.list-unstyled) li {
    padding-left: 1em;
    text-indent: -.7em;
    line-height:normal;
}

.main-content ul:not(.pagination):not(.list-unstyled) li:before {
    content: "•";
    font-size:25px;
    color: var(--color-lightgreen);
}





.color-darkgreen {
    color:var(--color-darkgreen);
}
.color-lightgreen {
    color:var(--color-lightgreen);
}
.color-grey {
    color: #b6b3ad;
}
body a.color-white {
    color:white;
}
.big {
    font-size:32px;
    font-family: var(--font-extrabold);
}
.bg-yellow {
    background-color:#ffec66;
}
.bg-darkgreen {
    background-color:var(--color-darkgreen);
    color:white;
}
.bg-lightgreen {
    background-color:var(--color-lightgreen);
    color:#fff;
}
.bg-grey {
    background-color:#f3eee5;
}

/* --- PADDINGS / MARGINS --- */
.padding-top-0      { padding-top: 0 !important; }
.padding-top-25     { padding-top: 25px; padding-bottom:25px; }
.padding-top-30     { padding-top: 30px; }
.padding-top-70     { padding-top: 70px; }
.padding-bottom-30  { padding-bottom: 30px; }
.padding-bottom-90  { padding-bottom: 90px; }
.padding-bottom-150 { padding-bottom: 150px; }
.margin-top-20      { margin-top: 20px; }
.margin-bottom-30   { margin-bottom: 30px; }

/* --- OVERFLOW --- */
.overflow-visible { overflow: visible !important; }
.overflow-hidden  { overflow: hidden !important;  }


.text-uppercase {
    text-transform: uppercase;
}

.multicolumn a {
    color:#009d3d;
}

@media(min-width: 768px) {
    .multicolumn {
        font-size:16px;
    }
    .no-padding {
        padding:0px;
    }

}

.small-text {
    font-size:14px;
    color:#b6b3ad;
    padding-bottom:20px;
}
.content-block {
    padding:25px 0px;
}
.content-block--lg {
    padding: 40px 0px;
}
@media screen and (max-width: 767px){
    .content-block--lg {
        padding:20px;
    }
}
.section-has-images {
    position:relative;
}
.section-has-images .img-left,
.section-has-images .img-right {
    display: none;
    max-width: 12%;
}
.section-has-bg-texture {   
    background-image: url("/web/staticatic/img/bg/bg-texture.jpg");
    background-size:cover;
}

.section-has-bg-himmel {
    background-image: url("/web/staticatic/img/bg/bg-himmel.jpg");
    background-size:cover;
}
.has-bg--holz-tomaten {
    background-image: url("/web/staticatic/img/bg/bg-holz-tomaten.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;

}
.has-bg--holz-paprika {
    background-image: url("/web/staticatic/img/bg/bg-holz-paprika.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
}
.has-bg--holz-eier {
    background-image: url("/web/staticatic/img/bg/bg-holz-eier.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
}
@media(max-width: 767px)  {
   .has-bg--holz-eier, .has-bg--holz-paprika, .has-bg--holz-tomaten {
       background-position:center top;
   }
}
.main-content {
    position:relative;
}
.img-left {
    position:absolute;
    left:0;
    bottom:0;
}
.img-right {
    right:0;
    bottom:0;
    position:absolute;
}
hr {
    margin:0;
}

@media screen and (min-width: 1400px) {
    .section-has-images .img-left,
    .section-has-images .img-right {
        display: block;
    }
}
@media(max-width: 767px) {
    .container .content-block {
        padding-left:0;
        padding-right:0;
    }
    .img-left, .img-right {
        display:none;
    }
}
.img-center {
    margin:0 auto;
}

.content-link {
    color:#009d3d;
    font-size:14px;
}

#socialmedia {
    padding:50px 0px;
}
@media(min-width: 768px) {
    .container {
        width:100%;
    }
    .section-container {
        width:100%;
    }

}


.half-container__left,
.half-container__right {
    padding: 20px;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    body {
        width:982px;
    }
    .container, .section-container {
        width: 100%;
    }
    .section-container__fullwidth {
        width:100%;
        margin:0 auto;
        padding:0px;
    }
}
@media(min-width: 992px) {
    .container {
        width:100%;

    }
    .section-container {
        width:830px;
    }
    .section-container__fullwidth {
        width:100%;
        margin:0 auto;
        padding:0px;
    }
}

@media (min-width: 1200px) {
    .container {
        width:100%;
    }
    .section-container {
        width:1030px;
    }
    .section-container__fullwidth {
        width:100%;
        margin:0 auto;
        padding:0px;
    }
    .section-container__middle {
        width:1285px;
        margin:0 auto;
    }
    .container-footer {
        padding:0px 40px;
    }
    .section-container + .section-container {
        padding-top:40px;
    }
    .half-container__left {
        max-width:670px;
        float:right;
        margin-right:40px;
        padding:40px 0px 40px 120px;
    }
    .half-container__right {
        max-width:670px;
        float:left;
        margin-left:40px;
        padding:40px 120px 40px 0px;
    }
}

@media (min-width: 1440px) {
    .section-container__fullwidth {
        width:100%;
        margin:0 auto;
        padding:0px;
    }
    .section-container {
        width:1030px;
    }
}

@media (max-width: 767px) {
    .teaser__text p{
        font-size:14px;
    }
    .section-container__fullwidth{
        padding:0px;
        margin:0px;
    }
    .clearfix__mobile {
        float:none;
        clear:both;
    }


    .content-block {
        padding:15px;
    }

    .half-container__left {
        padding:15px;
    }
    .half-container__right {
        padding:15px;
    }

}


.form-control {
    border-radius:0px;
}

.gradient-light--horizontal {
    background: rgb(243,238,229);
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YzZWVlNSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(left,  rgba(243,238,229,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(243,238,229,1)), color-stop(100%,rgba(255,255,255,1)));
    background: -webkit-linear-gradient(left,  rgba(243,238,229,1) 0%,rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(left,  rgba(243,238,229,1) 0%,rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(left,  rgba(243,238,229,1) 0%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to right,  rgba(243,238,229,1) 0%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3eee5', endColorstr='#ffffff',GradientType=1 );
}

::-webkit-input-placeholder
{
    color: #524d46;
    font-size:16px;
}

.form-group {
    margin-bottom:0px;
}

.hidden {
    display:none !important;
}

.goToLink {
    cursor:pointer;
}

.radio-fieldset {
    padding:10px 0px;
}
.radio-fieldset input {
    margin-right:10px;
    cursor: pointer;
}
.radio-fieldset label {
    cursor: pointer;
}

@media(max-width: 767px) {
    .radio-fieldset label {
        font-size:13px;
        margin:0px;
    }
    .radio-fieldset {
        padding:5px;
    }
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus,
.form-control:focus{
    border: 1px solid var(--color-lightgreen);
    box-shadow: none;
    outline: 0 none;
}

@media(min-width: 768px) {
    .display-table {
        display:table;
    }
    .display-table-cell {
        display:table-cell;
    }
}


/** ------- HEADLINES -------------------------------------------------------------------------------------------------- **/
h1, .h1,
h2, .h2 {
    font-family: var(--font-bold);
}

.content-block h2, .section-container h2,
.content-block--lg h2, .section-container h2,
.content-block .h2, .section-container .h2,
.content-block--lg .h2, .section-container .h2 {
    font-family: var(--font-light);
    margin-bottom:40px;
    color: var(--color-darkgreen);
}

h3, .h3,
h4, .h4 {
    font-family: var(--font-light);
}
h5, .h5,
h6, .h6 {
    font-family: var(--font-extrabold);
}
.headline-creative {
    font-family: var(--font-creative);
}
.headline-content {
    font-family: var(--font-light);
}



h1, .h1 {
    font-size: 90px;
}
@media screen and (min-width: 1200px) {
    h1, .h1 {
        font-size:130px;
    }
}
h2, .h2 {
    font-size:60px;
}
h3, .h3 {
    font-size:40px;
}
h4, .h4 {
    font-size:30px;
}
h5, .h5 {
    font-size:22px;
}
h6, .h6 {
    font-size:18px;
}


.headline-bold {
    font-family: var(--font-extrabold);
    font-size: 60px;
}
.hgroup-nomargin h1, .hgroup-nomargin h5, .hgroup-nomargin h2 {
    margin:0;
}
.hgroup-nomargin {
    margin-top: 20px;
    margin-bottom:20px;
}

h1.headline-creative,
.h1.headline-creative {
    line-height:1.2;
}

.font-light {
    font-family: var(--font-light);
}


@media (max-width: 767px) {
    h1, .h1 {
        font-size: 50px;
        hyphens: auto;
    }
    h2, .h2 {
        font-size:30px;
        line-height:30px;
    }
    .headline-bold {
        font-size:36px;
    }
    h3, .h3 {
        font-size:28px;
        line-height:28px;
    }
    h4, .h4 {
        font-size:24px;
        line-height:24px;
    }
    h5, .h5 {
        font-size:18px;
    }
    h6, .h6 {
        font-size:16px;
    }

}


.margin-0 {
    margin:0;
}
.margin-bottom-0 {
    margin-bottom:0 !important;
}
.margin-bottom-30 {
    margin-bottom:30px;
}
.margin-bottom-20 {
    margin-bottom:20px;
}
.margin-left-30 {
    margin-left:30px;
}

/** ------- embed responsive ------------------------------------------------------------------------------------------- **/

.embed-responsive-quadr {
    padding-bottom:100%;
}
.embed-responsive-video {
    padding-bottom: 33.334%;
}
.embed-responsive-66 {
    padding-bottom: 66.666666%;
}
.embed-responsive-blog-top-teaser {
    padding-bottom: 41.75%;
}
.embed-responsive-75 {
    padding-bottom: 75%;
}
.embed-responsive-56 {
    padding-bottom:56%;
}
.embed-responsive-58 {
    padding-bottom:58%;
}
.embed-responsive-7by4 {
    padding-bottom: 57.14286%;
}
.embed-responsive-7by5 {
    padding-bottom: 71.4285714%;
}
.embed-responsive-3by4 {
    padding-bottom: 113.333333%;
}
.embed-responsive-52 {
    padding-bottom:52.845%;
}
.embed-responsive-111 {
    padding-bottom: 111.666666%;
}
.embed-responsive-109 {
    padding-bottom:109%;
}
.embed-responsive-50 {
    padding-bottom: 50%;
}
.embed-responsive-26 {
    padding-bottom: 26.66667%;
}
.embed-responsive-portal-slider {
    padding-bottom: 40.625%;
}
@media screen and (max-width: 767px) {
    .embed-responsive-video {
        padding-bottom: 56%;
    }
    .embed-responsive-portal-slider {
        padding-bottom: 82%;
    }
}

/** ------- Buttons--------- ------------------------------------------------------------------------------------------- **/
.btn {
    border-radius:0px;
    border:0px;
    font-family: var(--font-bold);
    font-size:20px;
    padding:10px 15px;
}
.btn-default {
    background-color:var(--color-lightgreen);
    color:#fff;
}
.btn-secondary {
    color:#fff;
    border-radius:0px;
    background: #306645;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMwNjY0NSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMTQ1MWEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #306645 0%, #01451a 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#306645), color-stop(100%,#01451a));
    background: -webkit-linear-gradient(top,  #306645 0%,#01451a 100%);
    background: -o-linear-gradient(top,  #306645 0%,#01451a 100%);
    background: -ms-linear-gradient(top,  #306645 0%,#01451a 100%);
    background: linear-gradient(to bottom,  #306645 0%,#01451a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#306645', endColorstr='#01451a',GradientType=0 );
}
.btn-orange {
    background-color:#ff8c00;
    color:#fff;
}
.btn-fullwidth {
    width:100%;
}
@media(max-width: 767px) {
    .btn {
        font-size:15px;
    }
}


/** ------- BREADCRUMBS ------------------------------------------------------------------------------------------------ **/
.breadcrumb-wrapper {
    border-radius:0px;
    background-color: var(--color-darkgreen);
}
.breadcrumb {
    background-color:inherit;
    color:#fff;
    border-radius:0px;
    height:50px;
    margin-bottom:0px;
    padding: 0px 15px;
    line-height:50px;
}
.breadcrumb a {
    color:#fff;
    font-size:16px;
}
.breadcrumb li + li a{
    padding:0px 10px;
}
.breadcrumb li.active {
    color:#fff;
    font-family: var(--font-bold);
}
.breadcrumb > li + li:before {
    content: "\E001";
    font-family: iconfont;
    font-size:10px;
    padding:0 10px;
}

.intranet-bar li + li {
    margin-left:50px;
}
.intranet-bar li {
    float:left;
}
.intranet-bar span.icon {
    padding-right:10px;
    color:#99b5a3;
}
.mfp-gallery-popup .mfp-arrow-left:before, .mfp-gallery-popup .mfp-arrow-left .mfp-b, .mfp-gallery-popup .mfp-arrow-right:before, .mfp-gallery-popup .mfp-arrow-right .mfp-b {
    border:none;
}
.mfp-gallery-popup .slider-arrow {
    font-size:50px;
    color:#fff;
    background:none;
    border:none;
}
.mfp-gallery-popup .mfp-arrow-left {
    transform: rotate(180deg);
    left: 0;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    margin-left: 60px;

}
.mfp-arrow-left:focus, .mfp-arrow-right:focus {
    outline:none;
}
.mfp-counter {
    color:#fff;
    font-size:20px;
}
.mfp-gallery-popup .mfp-arrow-right {
    right: 0;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    margin-right: 60px;

}
@media(max-width: 767px) {
    .breadcrumb-wrapper {
        position:relative;
    }
    .breadcrumb-mobile-open {
        height:auto;
        background-color:#336a47;
        position:absolute;
        top:0;
        width:100%;
        z-index:1;
    }
    .breadcrumb .icon-mob-breadcrumb {
        font-size:3px;
        padding:10px;
        color:#99b5a3;
        vertical-align: super;

    }
    .breadcrumb-mobile-header {
        color:#99b5a3;
    }
    .breadcrumb-mobile-header a.active {
        font-family: var(--font-bold);
    }
    .breadcrumb > li {
        display:block;
        padding:0px 30px;
    }
    .breadcrumb>li+li:before {

        content:'';
        display:none;
    }
    .breadcrumb {
        padding:0px;
    }
    .breadcrumb-wrapper .container {
        padding:0px;
    }
    .breadcrumb-mobile-open > li.active {
        background-color: var(--color-darkgreen);
    }
    .breadcrumb .icon-arrow-slider {
        line-height: 50px;
        width: 50px;
        position: absolute;
        cursor: pointer;
        right: 15px;
        text-align: center;
    }
}


/** ------- BADGE ------------------------------------------------------------------------------------------- **/
.badge {
    background-image: url(/web/static/img/badge.png);
    background-size: contain;
    background-repeat: no-repeat;
    min-width: 250px;
    min-height: 250px;
    font-size: 24px;
    color: #004220;
    text-align: center;
    padding: 73px;
    max-height: 400px;
    max-width: 400px;
    background-position: center center;
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
}

.badge .big {
    font-size:125px;
    color:#009d3d;
    font-family: var(--font-creative);
    line-height: 170px;
    height: 90px;
}
.badge span.big {
    display: inline-block;
}

.img-with-badge .badge {
    position:absolute;
    top:40px;
    right:50px;
}

@media screen and (min-width: 768px) {
    .badge-small {
        max-height: 250px;
        max-width: 250px;
        padding:35px;
        font-size:22px;
        line-height:22px;
    }
    .badge-small .big {
        line-height: 189px;
        height: 76px;
    }
}
@media(max-width: 767px) {
    .badge .big {
        font-size: 30px;
        line-height: 50px;
        height: 30px;
    }
    .badge {
        min-width: 50px;
        min-height: 50px;
        max-width: 150px;
        font-size: 13px;
        padding: 30px;
        background-position: center center;
        -webkit-transform: rotate(20deg);
        -ms-transform: rotate(20deg);
        transform: rotate(20deg);
    }
    .img-with-badge .badge {
        position: absolute;
        top: -20px;
        right: 0px;
    }
}

/** ------- PAGINATION ------------------------------------------------------------------------------------------- **/
.pagination {
    float:right;
    margin:0px 0px 20px;
}

.pagination > li span.icon {
    font-size:12px;
}
.pagination-counter {
    font-size:16px;
    line-height:30px;
}
.pagination > li > a {
    color:#004220;
}
.pagination > li > a[href], .pagination > li > span {
    color:#009d3d;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    color:#000;
    font-family: var(--font-bold);
    text-decoration:none;
}
.pagination-wrapper {
    margin-bottom:10px;
}
@media(max-width: 767px) {
    .pagination-counter {
        text-align:center;
    }
    .has-pagination {
        text-align:center;
    }
    .has-pagination .pagination {
        float:none;
    }

}

/** ------- Shadow ------------------------------------------------------------------------------------------- **/
.has-shadow:before, .has-shadow:after {
    z-index: 0;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 40px;
    width: 50%;
    top: 80%;
    background: #777;
    box-shadow: 0 15px 20px rgba(119, 119, 119, 0.49);
    -webkit-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);

}
.has-shadow:after {
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    right: 40px;
    left: auto;
}
.has-shadow .row {
    position:relative;
    z-index:1;
}
.has-shadow {
    position:relative;
}
/** ------- ANIMATIONS ------------------------------------------------------------------------------------------- **/

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 15px, 0);
        transform: translate3d(0, 15px, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 15px, 0);
        transform: translate3d(0, 15px, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}


@-webkit-keyframes fadeInDown {
    0% {
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }
    100% {
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes fadeInDown {
    0% {
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }
    100% {
        -webkit-transform: none;
        transform: none;
    }
}
.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@media(min-width: 768px) {
    .img-hover {
        position: relative;
        float: left;
        overflow: hidden;
        margin: 10px 1%;
        min-width: 320px;
        max-width: 480px;
        max-height: 360px;
        width: 48%;
        text-align: center;
        cursor: pointer;
    }
    .img-hover img {
        -webkit-transition: opacity 0.3s, -webkit-transform 0.8s;
        transition: opacity 0.3s, transform 0.8s;
        -webkit-transform: scale(1.12);
        transform: scale(1.12);
        position: relative;
        display: block;
        min-height: 100%;
        max-width: 100%;
        opacity: 1;
    }
    .img-hover:hover img {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: opacity 0.3s, -webkit-transform 1.1s;
        transition: opacity 0.3s, transform 1.1s;
    }
    .img-hover:before, .img-hover-simple:before {
        content: "\E01A";
        font-family: iconfont;
        text-align: center;
        color:#fff;
        font-size: 90px;
        z-index: 2;
        width:90px;
        margin:0 auto;
        position:absolute;
        top:50%;
        left:0;
        right:0;
        margin-top:-60px;
        opacity: 0;
        transition-delay:0.2s;
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        -webkit-transition: opacity 0.25s, -webkit-transform 0.5s;
        transition: opacity 0.25s, transform 0.5s;
    }

    .img-hover:hover:before, .img-hover-simple:hover:before {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
    }
    .img-hover-simple:after {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        z-index: -1;
        content: '';

    }
    .img-hover-simple >div {
        opacity:1;
        -webkit-transition: opacity 0.35s;
        transition: opacity 0.35s;
    }

    .img-hover--teaser {
        overflow:hidden;
    }
    .img-hover--teaser img {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -webkit-backface-visibility: hidden;
    }
    .img-hover--teaser:hover img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -webkit-backface-visibility: hidden;
    }

    .btn {
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
        color:#fff;
        position:relative;
        border-color: #37474f;
        z-index:1;
    }
    .btn {
        background-color: var(--color-lightgreen);
        overflow: hidden;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
    }
    .btn:hover, .teaser:hover .btn {
        color:#fff;
        background-color: var(--color-lightgreen);

    }
    .btn:hover::before, .btn:hover::after, .teaser:hover .btn:before, .teaser:hover .btn:after {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    .btn:hover::after {
        -webkit-transition-delay: 0.175s;
        transition-delay: 0.175s;
    }
    .btn:before {
        background: #5ba919;
    }
    .btn:before, .btn:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 100%;
        left: 0;
        z-index: -1;
        -webkit-transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    }
    .btn:after {
        background: #509416;
    }

    /*.btn-default:hover, .btn-secondary:hover, .teaser:hover .btn-default, .teaser:hover .btn-secondary {*/
        /*background:#5ba919;*/
    /*}*/

    .btn:hover {
        color:#fff;
    }

    @keyframes stickyFrames{
        0% {
            opacity:0;
            transform:  translate(0px,-89px)  rotate(0deg) ;
        }
        24% {
            opacity:1;
            transform:  translate(-56px,-63px)  rotate(31deg) ;
        }
        50% {
            transform:  translate(9px,-52px)  rotate(-27deg) ;
        }
        72% {
            transform:  translate(-72px,-21px)  rotate(23deg) ;
        }
        100% {
            opacity:1;
            transform:  translate(0px,0px)  rotate(0deg) ;
        }
    }


    @-moz-keyframes stickyFrames{
        0% {
            opacity:0;
            -moz-transform:  translate(0px,-89px)  rotate(0deg) ;
        }
        24% {
            opacity:1;
            -moz-transform:  translate(-56px,-63px)  rotate(31deg) ;
        }
        50% {
            -moz-transform:  translate(9px,-52px)  rotate(-27deg) ;
        }
        72% {
            -moz-transform:  translate(-72px,-21px)  rotate(60deg) ;
        }
        100% {
            opacity:1;
            -moz-transform:  translate(0px,0px)  rotate(0deg) ;
        }
    }

    @-webkit-keyframes stickyFrames {
        0% {
            opacity:0;
            -webkit-transform:  translate(0px,-89px)  rotate(0deg) ;
        }
        24% {
            opacity:1;
            -webkit-transform:  translate(-56px,-63px)  rotate(31deg) ;
        }
        50% {
            -webkit-transform:  translate(9px,-52px)  rotate(-27deg) ;
        }
        72% {
            -webkit-transform:  translate(-72px,-21px)  rotate(60deg) ;
        }
        100% {
            opacity:1;
            -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
        }
    }


}