.teaser-slider {
    position: relative;
}

.teaser-slider__item > * {
    transition: all 0.3s ease;
}

.teaser-slider__title {
    color: var(--color-darkgreen);
}

.teaser-slider__arrow-left,
.teaser-slider__arrow-right {
    font-size: 24px;
    position: absolute;
    top: 8px;
    bottom: 8px;
    z-index: 2;
    cursor: pointer;
    color: #fff;
    padding: 10% 20px;
    text-shadow: 2px 2px 30px #555555;
    background-color: #00421F75;
}

.teaser-slider__arrow-left {
    margin-left: -10px;
}

.teaser-slider__arrow-right.slick-disabled,
.teaser-slider__arrow-left.slick-disabled {
    opacity: 0.5;
    background-color: transparent;
}

.teaser-slider__arrow-left {
    left: 10px;
}

.teaser-slider__arrow-right {
    right: 10px;
}

.teaser-slider__item {
    position: relative;
    border-right: 1px solid #fff;
}

.teaser-slider__item::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 1;
    transition: all 0.3s ease-in;
    opacity: 1;
}

@media screen and (min-width: 768px) {
    .teaser-slider__item:before {
        background: linear-gradient(180deg, rgba(0, 66, 31, 0.2) 0%, rgba(0, 66, 31, 0.9) 100%);
        opacity: 0;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom:0;
        right: 0;
        z-index: 1;
        transition: all 0.3s ease-in;
    }

    .teaser-slider__item:hover:after {
        opacity: 0;
    }

    .teaser-slider__item:hover:before {
        opacity: 1;
    }
}


.teaser-slider__body {
    overflow: hidden;
}

.teaser-slider__body {
    position: absolute;
    left: 16px;
    bottom: 16px;
    right: 16px;
    color: #fff;
    z-index: 2;
    line-height: 1.15;
}

.teaser-slider__headline {
    font-family: var(--font-bold);
    font-size: 22px;
    text-decoration: none;
    color: #fff;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

a.teaser-slider__headline:hover, a.teaser-slider__headline:focus {
    color: #fff;
    text-decoration: none;
}

.goToLink:hover a.teaser-slider__headline {
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .teaser-slider__content {
        display: none;
    }
}


@media screen and (min-width: 768px) {
    .teaser-slider__item:hover .teaser-slider__content-wrapper {
        transform: translateY(0);
    }

    .teaser-slider__content-wrapper {
        transform: translateY(60px);
        transition: transform 0.3s ease-in;
    }

    .teaser-slider__content {
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 54px;
        margin-top: 4px;
    }

    .teaser-slider__body {
        left: 32px;
        bottom: 32px;
        right: 32px;
    }

    .teaser-slider__headline {
        font-size: 32px;
    }
}

