.blog-slider__headline {
    padding:20px 0px;
    position:relative;
}
.blog-slider__headline .h6 {
    margin-top:0;
    margin-bottom:0;
}
.blog-slider__headline-wrapper:hover a.h6, .blog-slider__headline .h6:hover {
    color: #fff;
    text-decoration: none;
}

.blog-slider__headline h3 {
    margin-top:10px;
    margin-bottom:0px;
}
.blog-slider__headline-wrapper {
    background-color: var(--color-lightgreen);
    color:#fff;
    position:relative;
}
.blog-slider__headline:after {
    position:absolute;
    top:-30px;
    left:0;
    content:'';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 30px;
    border-color: transparent transparent var(--color-lightgreen) transparent;
}
section .blog-slider {
    margin-bottom:60px;
}
.blog__side-bar a {
    color:#009d3d;
}

.blog__side-bar {
    padding:20px;
    height:100%;
    background-color: #c7dd72;
}
.blog-item {
    clear:both;
    float:none;
}
.blog-item + .blog-item {
    margin-top:20px;
}
.blog-sujet {
    width: 100%;
}
.block-margin {
    margin: 25px 0;
}
/* Blog-item Authors */
.blog__authors__pictures {
    margin-left:-5px;
    margin-right:-5px;
}
.blog__authors__picture-item {
    width:55px;
    float:left;
}
.blog__authors__picture-item  {
    margin:5px;
}

/* Blog-item Categories */
.blog__categories ul li a{
    color:#000;
}

.blog__categories li:before {
    color:#fff;
    margin-left:-10px;
}
.blog__categories li a{
    margin-left:10px;
}
.blog__categories li.active {
    background-color:#ecf4d0;
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 30px;
    padding-bottom: 10px;
    padding-top: 5px;
}
.blog__categories li.active a {
    color: var(--color-darkgreen);
}
.blog__categories li.active:before {
    color: var(--color-darkgreen);
}
.blog__categories li {
    padding-left:20px;
    text-indent: 0px;
}
.blog__categories li:before {
    margin-left:-20px;
}

.blog-content .blog__headline h2{
    margin-top:0px;
    margin-bottom:20px;
}
.blog-content .blog__headline h1 {
    word-wrap: break-word;
}
/* Blog-item Comments */
.blog__comments .blog__comments-item + .blog__comments-item {
    border-top:1px solid #fff;
    margin-top:15px;
    padding-top:10px;
}

@media screen and (min-width: 768px){
    .blog-top-teaser__img{
        object-fit: cover;
    }
}



@media(max-width: 767px) {
    .blog-slider__headline h3 {
        font-size:22px;
    }

    .blog-slider__headline:after {
        top:-15px;
        border-width: 0 15px 15px;
    }
    .blog-slider__headline .h6 {
        font-size:14px;
    }
    section .blog-slider {
        margin-bottom:10px;
    }
    .teaser-wrapper__blog h4 {
        font-size:20px;
    }

    .teaser-wrapper__blog .teaser__body--date {
        font-size: 14px;
    }
    .blog-content h3 {
        font-size:22px;
    }
    .blog-content h2 {
        font-size:30px;
    }
}


/** ------- BLOG KATEGORIE ----------------------------------------------------------------------------------------------------- **/

.teaser-wrapper__blog h5 {
    font-family: var(--font-default);
}

.teaser-wrapper__blog > .row--same-height + .row--same-height {
    margin-top:30px;
}

/** ------- BLOG TIPP ----------------------------------------------------------------------------------------------------- **/
.blog-content .tipp-text {
    position:relative;
    padding:40px;
}

@media(max-width: 767px) {
    .blog-content .tipp-text {
        padding: 20px;
    }
}

.blog-content .tipp-quote-top {
    left:15px;
}

.blog-content .tipp-quote-bottom {
    right:15px;
}

/** ------- BLOG TAGS ----------------------------------------------------------------------------------------------------- **/

.blog-tag {
    background-color:#f3eee5;
    padding: 10px 15px;
    width:auto;
    float:left;
}
.blog-tag a {
    font-size:20px;
    color: var(--color-darkgreen);
}
.blog-tag:hover {
    background-color:var(--color-lightgreen);
}
.blog-tag:hover a {
    color:#fff;
}
.blog-tag + .blog-tag {
    margin-left:20px;
}
.main-content .container .content-block .teaser .teaser__body a {
    margin-right: 0%; /*previously 2%*/
}

.pagination > li:last-child > a, .pagination-counter {
    font-size: 18px;
}

.isH2 {
    display: block;
    -webkit-margin-before: 0.83em;
    -webkit-margin-after: 0.83em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    font-weight: 500;
    line-height: 1.1;
    font-size: 60px;
    font-family: proximanova-light;
    color: #004220;
    margin-top: 0;
    margin-bottom: 20px;
}

.icon-disqus_info {
    color: #65b636;
    font-size: 26px;
    vertical-align: middle;
}

.disqus__info-text {
    display: inline-block;
    max-width: 80%;
    vertical-align: middle;
}

.blog-recommendations__title{
    color: var(--color-darkgreen);
    font-size: 25px;
    margin-bottom: 30px;
    font-family: var(--font-bold);
    text-align: center;
}

.blog-recommendations__text{
    color: var(--color-darkgreen);
    font-size: 20px;
    line-height: 1.2;
    font-family: var(--font-bold);
    margin: 15px 0;
}
.blog-teaser__date,
.blog-top-teaser__date {
    font-size: 14px;
    line-height: 1;
    color: var(--color-darkgreen);
}
.blog-top-teaser__title{
    font-size: 40px;
    line-height: 1;
    color: var(--color-darkgreen);
    font-family: var(--font-bold);
    letter-spacing: 0;
    margin-top: 10px;
}
.blog-top-teaser__content {
    padding: 70px 100px;
}
.blog-teaser__badges,
.blog-top-teaser__badges {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.blog-teaser__badge,
.blog-top-teaser__badge{
    padding: 6px;
    background-color: #F5F0E8;
    color: var(--color-darkgreen);
    margin-bottom: 5px;
    margin-right: 5px;
    display: inline-block;
    font-size: 14px;
    line-height: 1;
}
.blog-top-teaser__text{
    font-size: 16px;
    line-height: 1.1;
    margin: 20px 0;
}

.blog-teaser__article-preview {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

}
.blog-teaser__title{
    font-size: 25px;
    line-height: 1;
    color: var(--color-darkgreen);
    font-family: var(--font-bold);
    letter-spacing: 0;
    margin-top: 5px;
}
.blog-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
}
.blog-filter__category{
    padding: 10px 18px;
    background-color: #F5F0E8;
    color: var(--color-darkgreen);
    font-family: var(--font-bold);
    margin-bottom: 10px;
    margin-right: 10px;
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    transition: all 0.25s ease;
}
.blog-filter__category.is-active {
    background-color: var(--color-lightgreen);
}
.blog-filter__category a {
    color: var(--color-darkgreen);
}
.blog-filter__category.is-active a{
    color: #fff!important;
    text-decoration: none;
}
.blog-filter__category:hover a{
    text-decoration: none;
    color: #fff!important;
}
.blog-filter__category:hover {
    background-color: var(--color-lightgreen);
}

@media screen and (max-width: 767px) {
    .icon-disqus_info {
        display: none;
    }

    .disqus__info-text {
        display: block;
        max-width: 100%;
    }
    .blog-content .blog__headline h1 {
        font-size: 40px;
    }
    .blog-content .wysiwyg p a img,
    .blog-content .wysiwyg p img{
        width: 100% !important;
    }

    .main-content .blog-tag {
        margin-bottom: 5px;
        margin-left: 0;
        width: 100%;
    }
    .main-content .blog-tag:last-child {
        margin-bottom: 0;
    }
    .blog-top-teaser__content {
        padding: 10px 15px;
    }
    .blog-top-teaser__title{
        font-size: 20px;
    }
    .blog-filter__category{
        margin-bottom: 5px;
        margin-right: 5px;
        font-size: 12px;
        padding: 6px;
        text-align: center;
    }
}
@media screen and (min-width: 768px) and (max-width: 1800px){
    .blog-top-teaser__content {
        padding:  clamp(20px, 3.5vw, 70px) clamp(40px, 5vw, 100px);
    }
    .blog-top-teaser__title{
        font-size: 20px;
        margin-top: 5px;
    }
    .blog-top-teaser__text{
        margin: 10px 0;
    }
}