.audio-area__audio{
    width: 100%;
    margin: 12px 0;
}

.audio-teaser {
    padding: 20px 0;
}

.audio-teaser + .audio-teaser {
    border-top: 2px solid #f3eee5;;
}

@media screen and (min-width:768px){
    .audio-area__audio{
        margin-top: 16px;
        margin-bottom: 0;
    }
    .audio-teaser--pull-right {
        float: right!important;
    }
    .audio-teaser--pull-left {
        float: left!important;
    }
}