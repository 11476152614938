.quotation-block blockquote{
    border: none;
    padding: 0;
    margin-bottom: 0;
}
.quotation-block__author{
    font-size: 20px;
}


@media(max-width: 767px) {
    .quotation-block blockquote.big{
        font-size: 20px;
    }
    .quotation-block__author{
        font-size: 16px;
    }
}
