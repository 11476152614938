.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f3eee5;
    color: #000;
    padding: 12px 16px;
    z-index: 12;
}

.cookie-bar:after {
    content: "";
    position: absolute;
    height: 100vh;
    top: -100vh;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    z-index: -1;
}

@media screen and (min-width: 768px) {
    .cookie-bar {
        display: flex;
        align-items: center;
    }
}

.cookie-bar__text {
    font-size: 15px;
}

.cookie-bar__left{
    width: 100%;
    font-size: 14px;
}

@media scren and (min-width: 768px) {
    .cookie-bar__left{
        display: flex;
    }
}

.cookie-bar__text,
.cookie-bar__detail{
    display: inline-block;
}

@media screen and (min-width: 768px){
    .cookie-bar__text{
        margin-right:15px;
    }
}
.cookie-bar__detail-link{
    text-decoration: underline;
    font-size: 16px;
    color: #000;
}

.cookie-bar__detail-link +.cookie-bar__detail-link{
    margin-left: 15px;
}

.cookie-bar__detail-link:hover{
    text-decoration: none;
}


.cookie-bar__detail-btn-no-style{
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    color: inherit;
    cursor: pointer;
}

a.cookie-bar__detail-btn {
    color: #000;
    text-decoration: none;
    font-family: var(--font-bold);
}

a.cookie-bar__detail-btn:hover {
    color: #000;
    text-decoration: underline;
}

.cookie-bar__buttons {
    text-align: center;
}
@media screen and (min-width: 768px) {
    .cookie-bar__buttons {
        flex: none;
        margin-left: 16px;
        text-align: right;
    }
}

.cookie-bar__btn {
    margin-left: 12px;
}
@media screen and (max-width: 767px) {
    .cookie-bar__btn {
        margin-top: 12px;
    }
}


/* modal */
.cookie-modal .modal-content {
    border-radius: 0;
}
.cookie-modal .modal-header,
.cookie-modal .modal-body,
.cookie-modal .modal-footer {
    padding: 20px;
}
.cookie-modal__title{
    font-size: 30px;
    font-family: var(--font-bold);
    color: var(--color-darkgreen);
}
.cookie-modal__item {
    margin-bottom: 12px;
}
.cookie-modal__item--all {
    margin-bottom: 16px;
}
.cookie-modal__label {
    font-size: 18px;
    /*font-weight: bold;*/
}
.cookie-modal__link {
    display: inline-block;
    margin-left: 8px;
    text-decoration: underline;
}
.cookie-modal .modal-header .close {
    padding: 2px 15px 8px;
    margin: 0;
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 1;
    cursor: pointer;
    text-shadow: none;
    font-size: 40px;
}

/* switch */
.cookie-modal__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    margin-right: 8px;
}
.cookie-modal__switch--disabled {
    opacity: .5;
}
.cookie-modal__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}
.cookie-modal__switch-toggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 34px;
}
input:checked + .cookie-modal__switch-toggle,
input:disabled + .cookie-modal__switch-toggle {
    background-color: var(--color-lightgreen);
}
input:focus + .cookie-modal__switch-toggle {
    box-shadow: 0 0 2px var(--color-lightgreen);
}
input:checked + .cookie-modal__switch-toggle:before {
    transform: translateX(26px);
}
input:disabled + .cookie-modal__switch-toggle:before {
    transform: translateX(26px)!important;
}

[hidden]{
    display: none!important;
}
