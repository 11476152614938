.product-way-wrapper img {
    width:100%;
}

.product-way-item {
    position:relative;
    overflow:hidden;
}
.product-way-wrapper .center-item--vertical {
    height:100%;
}

.product-way-item--inner {
    position:absolute;
    top:0;
    width:650px;
    left:0;
    right:0;
    height:100%;
}

.product-way-item--inner.text-center {
    margin:0 auto;
}

.product-way-wrapper .color-light {
    color:#fff;
}
.product-way-wrapper .color-dark h4 {
    color:var(--color-darkgreen);
}

.product-way-wrapper .btn {
    margin-top:30px;
    position:relative;
}
.product-way-wrapper .btn-video {
    padding-left:50px;
    margin-right: 20px;
}
.product-way-wrapper .color-light .btn {
    background-color:var(--color-lightgreen);
    color:#fff;
    padding-right:20px;
}
.product-way-wrapper .color-light .btn .icon {
    margin-top:5px;
    margin-left:5px;
    position:absolute;
    top:0;
    left:0;
    background: rgb(154,187,25);
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzlhYmIxOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjMmQ4NjkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  rgba(154,187,25,1) 0%, rgba(194,216,105,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(154,187,25,1)), color-stop(100%,rgba(194,216,105,1)));
    background: -webkit-linear-gradient(top,  rgba(154,187,25,1) 0%,rgba(194,216,105,1) 100%);
    background: -o-linear-gradient(top,  rgba(154,187,25,1) 0%,rgba(194,216,105,1) 100%);
    background: -ms-linear-gradient(top,  rgba(154,187,25,1) 0%,rgba(194,216,105,1) 100%);
    background: linear-gradient(to bottom,  rgba(154,187,25,1) 0%,rgba(194,216,105,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9abb19', endColorstr='#c2d869',GradientType=0 );
    border-radius:50px;
    height:40px;
    width:40px;
    text-align: center;
    border:1px solid #9abb19 ;
    line-height:40px;
    margin-right:15px;
    font-size:16px;
    padding-left:4px;
}

.product-way-wrapper .color-dark .btn .icon {
    position:absolute;
    top:0;
    left:0;
    margin-top:5px;
    margin-left:5px;
    background: rgb(2,78,30);
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAyNGUxZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzMjZlNDkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  rgba(2,78,30,1) 0%, rgba(50,110,73,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(2,78,30,1)), color-stop(100%,rgba(50,110,73,1)));
    background: -webkit-linear-gradient(top,  rgba(2,78,30,1) 0%,rgba(50,110,73,1) 100%);
    background: -o-linear-gradient(top,  rgba(2,78,30,1) 0%,rgba(50,110,73,1) 100%);
    background: -ms-linear-gradient(top,  rgba(2,78,30,1) 0%,rgba(50,110,73,1) 100%);
    background: linear-gradient(to bottom,  rgba(2,78,30,1) 0%,rgba(50,110,73,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#024e1e', endColorstr='#326e49',GradientType=0 );
    border-radius:50px;
    height:40px;
    width:40px;
    text-align: center;
    border:1px solid #024e1e ;
    line-height:40px;
    margin-right:15px;
    font-size:16px;
    padding-left:4px;
}

.product-way-wrapper .color-dark .btn {

    color:#fff;
    border-radius:0px;
    background: #306645;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMwNjY0NSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMTQ1MWEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #306645 0%, #01451a 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#306645), color-stop(100%,#01451a));
    background: -webkit-linear-gradient(top,  #306645 0%,#01451a 100%);
    background: -o-linear-gradient(top,  #306645 0%,#01451a 100%);
    background: -ms-linear-gradient(top,  #306645 0%,#01451a 100%);
    background: linear-gradient(to bottom,  #306645 0%,#01451a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#306645', endColorstr='#01451a',GradientType=0 );
}

@media(min-width: 768px) {
    .product-way-item--left {
        opacity:0;
        -moz-transform: translateX(-200px);
        -webkit-transform: translateX(-200px);
        -o-transform: translateX(-200px);
        -ms-transform: translateX(-200px);
        transform: translateX(-200px);
        -webkit-transition: all 0.8s ease;
        -moz-transition: all 0.8s ease;
        -ms-transition: all 0.8s ease;
        -o-transition: all 0.8s ease;
        transition: all 0.8s ease;
    }
    .product-way-item--right {
        opacity:0;
        -moz-transform: translateX(200px);
        -webkit-transform: translateX(200px);
        -o-transform: translateX(200px);
        -ms-transform: translateX(200px);
        transform: translateX(200px);
        -webkit-transition: all 0.8s ease;
        -moz-transition: all 0.8s ease;
        -ms-transition: all 0.8s ease;
        -o-transition: all 0.8s ease;
        transition: all 0.8s ease;
    }
    .product-animation .product-way-item--left, .product-animation .product-way-item--right {
        opacity:1;
        -moz-transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -o-transform: translateX(0px);
        -ms-transform: translateX(0px);
        transform: translateX(0px);
        -webkit-transition: all 0.8s ease;
        -moz-transition: all 0.8s ease;
        -ms-transition: all 0.8s ease;
        -o-transition: all 0.8s ease;
        transition: all 0.8s ease;
        transition-delay:1.5s;
    }
}

.mfp-video .mfp-close {
    margin-top:-60px;
}

@media(min-width: 768px) {
    .product-way-wrapper .color-light .btn:hover:before {
        box-shadow: inset 0 0 0 45px #5ba919;

    }
    .product-way-wrapper .color-dark .btn:hover:before {
        box-shadow: inset 0 0 0 45px #1f412c;
    }

    .product-way-wrapper .btn-default:hover {
        background-color:#a2c614;
    }
}

.product-way-wrapper h4,
.product-way-wrapper  h6 {
    margin-bottom:20px;
}
.product-way-wrapper h4 {
    font-family:var(--font-bold);
}
.product-way-item--inner .product-way-item--left {
    width:45%;
    text-align: right;
    float:left;
    position:absolute;
    left: 0;
}
.product-way-item--inner .product-way-item--right {
    width:45%;
    text-align: left;
    float:right;
    position:absolute;
    left:55%;
}
@media screen and (max-width: 767px) {
    .product-way-item--inner .product-way-item--left, .product-way-item--inner .product-way-item--right {
        position: relative;
    }
}


.product-way-counter {
    font-size:80px;
    font-family: var(--font-creative);
    border-radius:50%;
    text-align: center;
    height:100px;
    width:100px;
    line-height:150px;
    position:relative;
    margin-left: -50px;
    margin-top: 10px;
    margin-bottom: 10px;
}


.color-dark .product-way-counter {
    border-color: var(--color-darkgreen);
    color:var(--color-darkgreen);
}

.bg-brown-transparent {
    background-color: rgba(69, 53, 51, 0.45);
    padding:30px;
}

.dots {

    position: relative;
    z-index: 1;
    margin: 0 0 0 -1px;
    width: 10px;
}
.color-dark .dots {
    background: url(/web/static/img/weg-eines-produktes/points-green.png) 0 0 repeat-y;

}
.color-light .dots {
    background: url(/web/static/img/weg-eines-produktes/points-white.png) 0 0 repeat-y;

}

.dots-top {
    height:2px;
}
.product-animation .dots-top {
    height:40%;
    -moz-transition: height .5s linear;
    -o-transition: height .5s linear;
    -webkit-transition: height .5s linear;
    transition: height .5s linear;
}

.product-animation--ended .dots-top {
    height:40%;
}
.product-animation--ended .dots-bottom {
    height:48%;
}

.dots-bottom {
    height:2px;
}
.product-animation .dots-bottom {
    -moz-transition: height .5s linear;
    -o-transition: height .5s linear;
    -webkit-transition: height .5s linear;
    transition: height .5s linear;
    height:48%;
    transition-delay: 2.2s;
}
.dots-wrapper {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
}
.product-way-item:last-child .dots-bottom {
    display: none;
}

.product-way-counter {
    opacity:0;

}
.product-animation .product-way-counter {
    opacity:1;
    transition: 0.2s;
    transition-delay:0.5s;
}


.product-way-counter--circle {
    width:100px;
    height:100px;
    border-radius:50px;
    border: 6px solid #fff;
}
.color-dark .product-way-counter--circle {
    border: 6px solid var(--color-darkgreen);
}
.product-way-counter--circle {
    clip: rect(0px, 100px, 100px, 50px);
}
.product-way-counter--circle--full, .product-way-counter--circle--half {
    clip: rect(0px, 50px, 150px, 0px);
}
.product-way-counter--circle--full, .product-way-counter--circle--half, .product-way-counter--circle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 1s;
}
.product-way-counter--circle--full, .product-way-counter--circle--half, .product-way-counter--circle {
    width: 100px;
    height: 100px;
    position: absolute;
    border-radius: 50%;
}

.product-way-counter--circle--full,
.product-way-counter--circle--half, .product-way-counter--circle {
    transform: rotate(0deg);
    transition: 800ms ease-in-out;
    transition-delay:1s;
}
.product-animation .product-way-counter--circle--full,
.product-animation .product-way-counter--circle--full .product-way-counter--circle {
    transform: rotate(180deg);
    transition-delay:.9s;
}
.product-animation .product-way-counter--circle--half .product-way-counter--circle {
    transform: rotate(152deg);
    transition-delay:.9s;

}
.product-animation .product-way-counter--circle--half .product-way-counter--circle--fix {
    transform: rotate(304deg);

}
 .product-way-counter--inside {
    opacity: 0;
    transition: 0.5s;
    transform: scale(3);
}

.product-animation .product-way-counter--inside {
    opacity: 1;
    transition: 0.4s;
    transform: scale(1);
    transition-delay:0.5s;
}


@media(max-width: 767px) {
    .product-way-counter--inside {
        opacity:1;
    }
    .product-way-item--inner {
        position:static;
        width:100%;
        height:auto;
        padding:15px;
    }
    .product-way-item .color-light {
        color:#000;
    }
    .product-way-item h4 {
        color: var(--color-darkgreen);
        font-size:22px;
    }
    .product-way-item h5 {
        color: var(--color-darkgreen);

    }
    .product-way-item h6 {
        font-size:16px;
    }
    .product-way-item--inner .product-way-item--left, .product-way-item--inner .product-way-item--right {
        width:100%;
        float:none;
        clear:both;
        text-align:center;
        margin-bottom:20px;
        left:0;
    }
    .product-way-item .bg-brown-transparent {
        background-color:transparent;
        padding:0;
    }
    .product-way-wrapper  .btn {
        margin-top:10px;
    }
    .product-way-wrapper .product-way-item .btn .icon {
        height: 30px;
        width: 30px;
        line-height: 30px;
        font-size:13px;
    }
    .product-way-counter {
        opacity:1;
        color:#fff;
    }
    .dots-top, .dots-bottom {
        height: 40px;
    }
    .product-way-item:last-child .dots-bottom {
        display:block;
    }
    .product-way-wrapper .color-dark .btn {
        background-color: #a2c614;
        background:#a2c614;
    }
    .product-way-wrapper .color-dark .btn .icon {
        background: #9abb19;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: linear-gradient(to bottom,#9abb19 0,#c2d869 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9abb19',endColorstr='#c2d869',GradientType=0);
        border: 1px solid #9abb19;
    }

    .product-way-counter {
        font-size: 40px;
        font-family: rukola;
        border-radius: 50%;
        border: 3px solid #fff;
        text-align: center;
        height: 50px;
        width: 50px;
        line-height: 70px;
        position: relative;
        margin-left: -25px;
        margin-bottom: 10px;
        bottom: 0;
    }
}

@media screen and (min-width: 767px) and (max-width: 1199px){
    .product-way-item .wysiwyg li {
        line-height:2rem;
    }
    .product-way-wrapper h4 {
        font-size: 20px;
    }
    .product-way-wrapper .btn {
        margin-top: 10px;
    }
}