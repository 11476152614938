
@media(min-width: 768px) {

    .circle-wrapper{
        /*display:table;*/
        /*width:400px;*/
        /*position:relative;*/
        max-width: 690px;
        max-height: 690px;
    }
    @keyframes fadeIn{
        0% {
            transform:  scaleX(0.2) scaleY(0.2) ;
            opacity:0;
        }

        100% {
            transform:  scaleX(1.0) scaleY(1.0) ;
            opacity:1;
        }
    }


    @-webkit-keyframes fadeIn {
        0% {
            -webkit-transform:  scaleX(0.2) scaleY(0.2) ;
            opacity:0;
        }
        100% {
            -webkit-transform:  scaleX(1.0) scaleY(1.0) ;
            opacity:1;
        }
    }

    .circle--inner-item {
        /*display: table-cell;*/
        /*vertical-align: middle;*/
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);


    }
    .circle--inner-item img {
        animation: fadeIn linear 0.3s;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
        animation-fill-mode:forwards;
        -webkit-animation: fadeIn linear 0.3s;
        -webkit-animation-iteration-count: infinite;
        -webkit-transform-origin: 50% 50%;
        -webkit-animation-fill-mode:forwards;
    }

    .circle--outer-item {
        top: 43%;
        left: 43%;
        position:absolute;
        font-size:50px;
        color: var(--color-lightgreen);
        transform: translateY(0px) translateX(0px);
        transform:scale(0.01);
        opacity:0;

    }

    .animateBg .circle-content--white-bg {
        background-color:var(--color-lightgreen);
        color:#fff;
        border: 6px solid var(--color-darkgreen);
        transform:scale(1.5);
        transition: all 0.2s;
    }

    .animateBg .circle-content--white-bg .icon {
        color:#fff;
    }

    .circle-content--text {
        color: var(--color-darkgreen);
        text-align: center;
        text-transform: uppercase;
        width:100px;
        opacity:0;
        transform:translateY(-65px);
    }
    .circle-content--white-bg {
        background-color:#fff;
        text-align: center;
        display:table;
        border-radius:50%;
        width: 100px;
        height:100px;
        border:6px solid var(--color-lightgreen);
    }
    .circle--outer-item span {
        display:table-cell;
        vertical-align: middle;
        font-size:50px;
        color: var(--color-lightgreen);
        text-align:center;
        width:100px;
    }
    .circle--outer-item:hover span {
        text-decoration: none;
        color:#fff;
    }
    .circle--outer-item a:hover {
        text-decoration: none;
    }
    .circle--outer-item a:focus {
        outline:none;
        text-decoration: none;

    }

    .has-2-circles .circle--outer-item:first-child {
        top: 50%;
        margin-top: -40px;
    }

    .line {
        height: 160px;
        position: absolute;
        width: 100%;
        text-align: center;
        /*top: 39%;*/
        top: -10%;
        margin: 0 auto;
        left: 0;
        opacity:0;
        transform: rotate(0deg);
        transition: all 0.4s;
    }

    .line:before {
        /*opacity:0;*/
        content: '';
        position: absolute;
        background: #a2c614;
        height: 3px;
        max-width: 0px;
        margin: 0 auto;
        -webkit-transform-origin: 0px 0px;
        -ms-transform-origin: 0px 0px;
        transform-origin: 0 0;
        transform: rotateZ(270deg);
        width:100%;
        max-width:60px;
        transition: all 0.4s;
        -webkit-transition-delay: 2s;
        transition-delay: 2s;
    }




    .circle--outer-item .circle-content--white-bg {
        transition: all 0.4s;
    }

    @keyframes animationFrames{
        0% {
            opacity:0;
            transform:  scaleX(1.00) scaleY(1.00) ;
        }
        67% {
            opacity:1;
            transform:  scaleX(1.20) scaleY(1.20) ;
        }
        100% {
            transform:  scaleX(1.10) scaleY(1.10) ;
        }
    }

    @-webkit-keyframes animationFrames {
        0% {
            opacity:0;
            -webkit-transform:  scaleX(1.00) scaleY(1.00) ;
        }
        67% {
            opactiy:1;
            -webkit-transform:  scaleX(1.20) scaleY(1.20) ;
        }
        100% {
            -webkit-transform:  scaleX(1.10) scaleY(1.10) ;
        }
    }

    .circle--outer-item:hover .circle-content--white-bg{
        cursor:pointer;
        background-color: #fff;
        color:#fff;
        border-color: var(--color-darkgreen);
        animation: animationFrames linear 0.5s;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
        animation-fill-mode:forwards;
        -webkit-animation: animationFrames linear 0.5s;
        -webkit-animation-iteration-count: infinite;
        -webkit-transform-origin: 50% 50%;
        -webkit-animation-fill-mode:forwards;
    }


    .disabled-circle span {
        color: #b1b1b1;
    }
    .disabled-circle .circle-content--white-bg {
        background-color:lightgrey;
        border-color:#b1b1b1;

    }
    .disabled-circle:hover .circle-content--white-bg {
        cursor:not-allowed;
    }
    .disabled-circle a {
        pointer-events: none;
        cursor: default;
    }
    .disabled-circle:hover span {
        color:#b1b1b1;
    }

    .disabled-circle:hover .circle-content--white-bg{
        background-color:lightgrey;
        border-color:#b1b1b1;
        animation:none;
    }

    .disabled-line:before {
        background-color:#b1b1b1;
    }
    .animateBg.disabled-circle span {
        color:grey;
    }

    .animateBg.disabled-circle .circle-content--white-bg {
        background-color:#fff;
        border-color: #616161;
    }
}


@media(min-width: 992px) and (max-width: 1199px) {

}
@media(max-width: 767px) {

    .circle .embed-responsive-quadr {
        height:auto;
        padding-bottom:0px;
    }
    .circle .embed-responsive-quadr .embed-responsive-item {
        position:relative;
    }
    .circle--inner-item, .disabled-circle {
        display:none;
    }
    .circle--outer-item {
        width:100%;
        float:none;
        clear:both;
    }
    .circle--outer-item + .circle--outer-item {
        border-top:1px solid #aeaeae;
    }

    .circle-content--white-bg {
        padding:0;
        font-size: 40px;
        width:80px;
        height:80px;
        display:block;
        text-align:center;
        color:var(--color-lightgreen);
        float:left;
    }
    .circle-content--text {
        float:left;
        padding-top:24px;
    }
    .circle-wrapper {
        margin-top:10px;
    }
}