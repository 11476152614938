.scrolling-area {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(4rem / 16);
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
}

@media screen and (min-width: 768px){
    .scrolling-area--xs {
        overflow-y: unset;
        overflow-x: unset;
    }
}
.scrolling-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.2);
    background-color: transparent;
    transition: all 120ms ease;
    will-change: scroll-position;
}

.scrolling-area--xs::-webkit-scrollbar,
.scrolling-area::-webkit-scrollbar {
    width: calc(4rem / 16);
    height: calc(4rem / 16);
    transition: all 120ms ease;
    will-change: scroll-position;
}

.scrolling-area--xs::-webkit-scrollbar-thumb,
.scrolling-area::-webkit-scrollbar-thumb {
    transition: all 120ms ease;
    will-change: scroll-position;
}

@media screen and (max-width: 767px) {
    .scrolling-area--xs {
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        display: flex;
        flex-direction: row;
    }
    .scrolling-area__item--xs {
        width: 100%;
        max-width: 100%;
        flex-shrink: 0;
        scroll-snap-align: start;
    }
    .scrolling-area__item--xs.scrolling-area__item--mobile-cut {
        flex-basis: 90%;
    }
    .scrolling-area__item--xs.scrolling-area__item--mobile-cut--75 {
        flex-basis: 75%;
    }
}

.scrolling-area__item.scrolling-area__item--mobile-cut {
    flex-shrink: .1;
}

.scrolling-area__item {
    width: 100%;
    max-width: 100%;
    flex-shrink: 0;
    scroll-snap-align: start;
}