.social-teaser:hover img {
    transform: scale(1.05);
}

.social-teaser img {
    transition: all 0.25s ease-in;
}

.social-teaser {
    position: relative;
    border: 1px solid white;
}

.social-teaser--has-gutter {
    margin-top: 16px;
}

@media screen and (min-width: 768px) {
    .social-teaser--has-gutter {
        margin-top: 32px;
    }
}

.social-teaser::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(140.48deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    opacity: 0.5;
    transition: all 0.25s ease-in;
}

.social-teaser:hover::after {
    opacity: 1;
}

.social-teaser__link {
    position: absolute;
    bottom: 14px;
    right: 14px;
    line-height: 0;
    z-index: 2;
}

.social-teaser__icon {
    color: #fff !important;
    font-size: 24px;
}

@media screen and (max-width: 767px) {
    .social-teaser__icon {
        font-size: 18px;
    }

    .social-teaser__link {
        bottom: 6px;
        right: 6px;
    }
}