.pagination-new__wrapper,
.pagination-new {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.page-item-new {
    background-color: #F5F0E8;
    margin-left: 2px;
    font-family: var(--font-bold);
}
.page-link-new {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-item-new:hover a,
.page-item-new a {
    color: var(--color-darkgreen);
    text-decoration: none;
}
.page-item-new.disabled {
    opacity: 0.5;
}
.page-item-new.active {
    background-color: var(--color-darkgreen);
}
.page-item-new.active a {
    color: #fff;
    font-family: var(--font-extrabold);
}
.page-item--arrow .icon-arrow {
    padding: 0!important;
    color: var(--color-darkgreen);
}
.pagination-new__results{
    margin-top: 10px;
}

@media screen and (max-width: 767px){
    .pagination-new__results{
        text-align: center;
        margin-bottom: 15px;
    }
    .page-link-new {
        width: 34px;
        height: 34px;
    }
}