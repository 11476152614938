.styled-select>select {
    width:100%;
    cursor:pointer;
    padding-right:0;
    font-size:16px;
    font-family: var(--font-light);
    height:40px;
}
.has-select label {
    font-size:16px;
    font-family:var(--font-light);
}
.form-control-select {
    border:0px;
    box-shadow:none;
}
.styled-select {
    line-height:40px;
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height:40px;
    border: 1px solid #ccc;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

@media (min-width: 767px) {
    .styled-select:before {
        pointer-events: none;
        font-family: iconfont;
        content: "\E002";
        color: var(--color-darkgreen);
        position: absolute;
        right: 15px;
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        margin-right:5px;
    }
}

input[type=checkbox] {
    line-height: normal;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 20px;
    cursor: pointer;
    border: 1px solid #b6b3ad;
    background-color: white;
    border-radius: 0;
    color: #b6b3ad;
    font-size: 40px;
}

input[type=checkbox]+label:before {
    border-left: 0;
    color: #b6b3ad;
    content: "";
    border: 1px solid #b6b3ad;
    width: 20px;
    height: 20px;
    padding: 3px;
    font-size: 10px;
    display: inline-block;
    margin: -8px 9px 0 0;
    vertical-align: middle;
    cursor: pointer;
}
input[type=checkbox]:checked+label:before {
    content: "\E01F";
    font-family: iconfont;
    color:var(--color-darkgreen);
    font-size:14px;
    line-height:14px;
}

.form-group label {
    display:block;
    font-size:16px;
}

.form-group input {
    height:40px;
    width:100%;
    padding-left:10px;
}

.form-group .btn {
    height:40px;
    line-height:20px;
}
.form-group .hasDatepicker+.icon-calendar {
    float: right;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 30px;
    margin-bottom: 12px;
    color: var(--color-darkgreen);
}
.input-full-width {
    padding:20px 0px;
}

.form label {
    font-size:16px;
    font-family: var(--font-light);
    padding-top:20px;
}
.form-group + .form-group {
    padding-top: 20px;
}
.filter-wrapper .form-group + .form-group {
    padding-top:0px;
}
.vote-form .vote-form__info {
    display: none;
    background: #a2c614;
    color: #fff;
    padding: 20px;
    font-family: proximanova-semibold, sans-serif;
    font-size: 20px;
}
.vote-form.is-voted  .vote-form__info {
    display: inline-block;
}
.vote-form.is-voted label, .vote-form.is-voted .btn {
    opacity:.8;
    cursor: not-allowed;
}
.vote-form.is-voted .btn, .vote-form.is-voted .btn:after, .vote-form.is-voted .btn:before,
.teaser:hover .vote-form.is-voted .btn {
    content: none;
    background-color: #ccc;
    outline: none;
}

select::-ms-expand {
    display: none;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
}

@media(max-width: 767px) {

    .form label {
        padding-top:0;
    }
    .form-group {
        margin-bottom:15px;
    }
    .form-group + .form-group {
        padding-top: 0px;
    }
}

.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}.custom-radio:after {
     clear: both;
 }
.custom-radio__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: relative;
    float: left;
    margin-right: 7px;
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 16px;
    height: 16px;
    margin-top: .1em;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: #004220;
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: #004220;
}
.has-error .custom-radio__box {
    border-color: #004220;
}
.custom-radio__text {
    display: block;
    overflow: hidden;
}
