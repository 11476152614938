/** ------- VIDEOs ----------------------------------------------------------------------------------------------------- **/

.video-text {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    padding: 55px;
}

.video-text__title {
    font-family: var(--font-light);
    font-size:30px;
}

.video-text__time {
    font-family: var(--font-extrabold);
    font-size:50px;
    line-height: 40px;
}


.video-play {
    left: 50%;
    top:50%;
    position:absolute;
    width: 2.6em;
    height: 2.6em;
    border-radius: 2.6em;
    margin: -1.3em 0 0 -1.3em;
    border: 0;
    color: #fff;
    font-size: 35px;
    text-indent: 4px;
    text-shadow: none;
    box-shadow: none !important;
    background: rgba(80, 80, 80, 0.75);
    transition: all 0.3s;
    z-index:1;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.video-has-placeholder:hover .video-play {
    outline: 0;
    border-color: #fff;
    background-color: rgb(175, 206, 51);
    -webkit-box-shadow: 0 0 3em #fff;
    -moz-box-shadow: 0 0 3em #fff;
    box-shadow: 0 0 3em #fff;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
}

.video-play span:before {
    line-height: 2.6em;
}

@media(min-width: 768px) {
    .video-has-placeholder .video-item{
        display:none;
    }
}
@media (max-width: 767px) {

    .video-text {
        padding:10px;
    }
    .video-text__title {
        font-size:20px;
    }

    .video-text__time {
        font-size:30px;
        line-height:25px;
    }
    .video-play {
        width: 40px;
        height: 40px;
        border-radius: 2.6em;
        font-size:20px;
        margin: -20px 0 0 -20px;
    }
    .video-play span:before {
        line-height: 40px;
    }
}
/** ------- TEXT WITH IMAGE--------------------------------------------------------------------------------------------- **/
.text-with-image h5 {
    margin-bottom: 20px;
}
.text-with-image img {
    max-width: 100%;
    display: block;
    margin: 0 auto 15px auto;
}
@media screen and (min-width: 768px) {
    .text-with-image--left img  {
        float: left;
        margin: 0 30px 30px 0;
    }
    .text-with-image--right img {
        float: right;
        margin: 0 0 30px 30px;
    }
}


/** ------- HEADER IMAGE ----------------------------------------------------------------------------------------------- **/
.header-image {
    position:relative;
}
.header-image__headlines {
    padding: 30px 50px;
    position:absolute;
    right:0;
    margin:0 auto;
    top:0;
    left:0;
    color:#fff;
}
@media screen and (min-width: 1200px) {
    .header-image__headlines {
        padding: 70px 120px;
    }
}
.header-image img {
    width:100%;
}

@media(max-width: 767px) {
    .header-image__headlines {
        padding:10px 20px;
    }
    .header-image__headlines h5 {
        font-size:11px;
    }

}
/** ------- GALLERY - 2 IMAGES ----------------------------------------------------------------------------------------- **/
.gallery-two-images .row{
    margin:0;
}
.gallery-two-images .col {
    padding:0;
}


/** ------- GALLERY - 3 IMAGES ----------------------------------------------------------------------------------------- **/
.gallery-three-images .row{
    margin:0;
}
.gallery-three-images .col {
    padding:0;
}


/** ------- TABLES ----------------------------------------------------------------------------------------------------- **/
.table thead th, .wysiwyg table thead th {
    background-color: var(--color-lightgreen);
    font-family: var(--font-bold);
}

.table tbody th, .wysiwyg table tbody th {
    background-color: var(--color-lightgreen);
    font-family: var(--font-bold);
    border-bottom:1px solid #8e8c87;
}
.table thead + tbody th, .wysiwyg table thead + tbody th {
    background-color:#f3eee5;
    border-bottom:1px solid #b6b3ad;

}
.wysiwyg table {
    margin: 25px 0;
}
.table, .table .table, .wysiwyg table {
    width: 100%;
    max-width: 100%;
    margin: 0;
    background-color: transparent;
    font-size:16px;
}
.table>caption+thead>tr:first-child>td, .wysiwyg table>caption+thead>tr:first-child>td, .table>caption+thead>tr:first-child>th, .wysiwyg table>caption+thead>tr:first-child>th, .table>colgroup+thead>tr:first-child>td, .wysiwyg table>colgroup+thead>tr:first-child>td, .table>colgroup+thead>tr:first-child>th, .wysiwyg table>colgroup+thead>tr:first-child>th, .table>thead:first-child>tr:first-child>td, .wysiwyg table>thead:first-child>tr:first-child>td, .table>thead:first-child>tr:first-child>th, .wysiwyg table>thead:first-child>tr:first-child>th {
    border-top: 0;
}

.table>tbody>tr>td, .wysiwyg table>tbody>tr>td, .table>tbody>tr>th, .wysiwyg table>tbody>tr>th, .table>tfoot>tr>td, .wysiwyg table>tfoot>tr>td, .table>tfoot>tr>th, .wysiwyg table>tfoot>tr>th, .table>thead>tr>td, .wysiwyg table>thead>tr>td, .table>thead>tr>th, .wysiwyg table>thead>tr>th {
    padding: 10px 15px;
}

.table th, .table td, .wysiwyg table th, .wysiwyg table td {
    text-align: left;
    padding: 6px;
    line-height: 1.42857143;
    vertical-align: top;
    border-bottom:1px solid #b6b3ad;
}


@media(max-width: 767px) {
    table, .table, .wysiwyg table {
        width:100%;
        overflow-y:hidden;
        overflow-x:auto;
        table-layout:fixed;
        display:block;
    }
    table td, .table td, .wysiwyg table td, table th, .table th, .wysiwyg table th {
        width:1%;
    }
}
/** ------- TIPP-Container --------------------------------------------------------------------------------------------- **/

.tipp-headline {
    line-height:210px;
    height: 130px !important;
    font-size:150px;
    -moz-transform: rotate(-20deg);
    -webkit-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
    color:var(--color-darkgreen);
}
.tipp-text {
    margin-bottom:0px;
    padding:40px 0px;
    font-style: italic;
    font-size:18px;
    z-index:1;
    font-family: var(--font-default);
}
.tipp-quote-top, .tipp-quote-bottom {
    position:absolute;
    z-index:-1;
    font-size:95px;
    color:#ffe000;
}

.tipp-quote-top {
    top:0;
    left:-40px;
}

.tipp-quote-bottom {
    bottom:0;
    right:-40px;
}

@media(max-width: 767px) {
    .tipp-text {
        padding:20px 15px;
    }
    .tipp-headline {
        line-height:75px;
        font-size:75px;
        height: 75px !important;
        text-align:center;
    }
    .tipp-quote-bottom {
        right:0;
    }
    .tipp-quote-top{
        left:0;
    }
    .tipp-quote-top, .tipp-quote-bottom {
        font-size:55px;
    }
}

/** ------- Content-Slider Gallery --------------------------------------------------------------------------------------------- **/
.gallery-slide-item .icon-zoom {
    position:absolute;
    right:0;
    top:0;
    font-size:32px;
    color:#fff;
    background-color: rgba(0,69,25,0.75);
    padding:10px;
    text-decoration: none;
}
.gallery-slide-nav-item img {
    margin: 0 auto;
}
.js-gallery-slider {
    margin-bottom:20px;
}
.slick-prev:focus,
.slick-next:focus {
    outline: none;
}

.js-gallery-slider .slick-prev:before,
.js-gallery-slider .slick-next:before,
.js-mobile-slider .slick-prev:before,
.js-mobile-slider .slick-next:before {
     position:absolute;
     top:50%;
     margin-top:-30px;
     content: "\E001";
     font-size:60px;
     font-family: iconfont;
     color:#fff;
     text-shadow:0px 0px 25px #000;
 }
.slick-disabled {
    display:none;
}
.js-gallery-slider .slick-prev:before,
.js-mobile-slider .slick-prev:before {
    left:30px;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.js-gallery-slider .slick-next:before,
.js-mobile-slider .slick-next:before {
    right:30px;
}
.slider-nav .slick-slide {
    overflow: hidden;
}
.js-gallery-slider-nav .slick-prev:before,
.js-gallery-slider-nav .slick-next:before,
.js-mobile-slider .slick-prev:before,
.js-mobile-slider .slick-next:before  {
    position:absolute;
    top:50%;
    margin-top:-17px;
    content: "\E001";
    font-size:35px;
    font-family: iconfont;
    color:#fff;
}

.js-gallery-slider .slick-next,
.js-mobile-slider .slick-next {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 90px;
    border: 0px;
    background-color: transparent;
    z-index:1;
}

.js-gallery-slider .slick-prev,
.js-mobile-slider .slick-prev {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 90px;
      border: 0px;
      background-color: transparent;
      z-index: 1;
  }

.js-gallery-slider-nav .slick-prev {
    background-color: rgba(0,69,25,.75);
    position: absolute;
    top: 0;
    left: 0px;
    height: 100%;
    width: 50px;
    border: 0px;
    z-index: 1;
}

.js-gallery-slider-nav .slick-next {
    background-color: rgba(0,69,25,.75);
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    width: 50px;
    border: 0px;
}
.js-gallery-slider-nav .slick-prev:before {
    left:15px;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.js-gallery-slider-nav .slick-next:before {
    right:15px;
}
.slider-nav .slick-slide:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-lightgreen);
    opacity: 0;
    transition: opacity 300ms;
    pointer-events: none;
}
.slider-nav .slick-current.slick-active:before {
    opacity: 0.5;
}


@media(max-width: 767px) {
    .js-gallery-slider .slick-prev:before, .js-gallery-slider .slick-next:before {
        font-size:30px;
        margin-top:-15px;
    }
    .js-gallery-slider .slick-next:before {
        right:10px;
    }
    .js-gallery-slider .slick-prev:before {
        left:10px;
    }

}

/** ------- History Boxes --------------------------------------------------------------------------------------------- **/
.history-box {
    background-color:#f3eee5;
    padding:25px;
}
.history-box-wrapper {
   margin: 30px 0px;
}
.history-box h4, .history-box .h4{
    color: var(--color-darkgreen);
    font-family: var(--font-extrabold);
    margin-top:0px;
}

.history-box + .history-box {
    margin-top:20px;
}


/** ------- ProductSites --------------------------------------------------------------------------------------------- **/
.product-tab {
    background-color:#ecf4d0;
    min-height:80px;
    position: relative;
}

.product-tab .nav-tabs > li > a {
    height:40px;
    font-size:20px;
    line-height:40px;
    padding:0px 30px;
    color: var(--color-darkgreen);
    font-family: var(--font-bold);
    border-bottom: none;
    border-right: 1px solid var(--color-lightgreen);
    border-radius:0px;
    margin-right:0px;
}
.product-tab .nav-tabs > li:last-child a{
    border-right:0px;
}
.product-tab .nav-tabs li {
    padding:20px 0px;
}
.nav-tabs li:before, #inline-nav li:before {
    display:none;
}
.nav-tabs > li.active {
    background-color: var(--color-lightgreen);
    margin-left:-1px;
}

.product-tab .nav-tabs {
    margin-left:40px;
}
.nav-tabs > li.active a:before {
    border-width: 10px 15px 0;
    z-index:2;
}
.nav-tabs > li > a:before, .nav-tabs>li.active-clone a:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border-style: solid;
    border-color: var(--color-lightgreen) transparent;
    border-width: 0px 15px;
    height: 0;
    width: 0;
    height: 0;
    display: block;
    z-index: 0;
    transition: all .15s ease;
}

.product-tab .nav-tabs > li > a:before {
    margin-top:20px;
}
.product-tab.affix {
    top:0;
    z-index:100;
    width:100%;
    position: fixed;
}
/*product sub nav */
.nav-tabs__subnav {
    position: relative;
    left: 0;
    z-index: 100;
    display: none;
}
.product-tab .nav-tabs li.active .nav-tabs__subnav, .nav-tabs__subnav.is-open {
    display: block;
}
.nav-tabs__subnav ul.nav-tabs {
    margin-left: 0;
    text-align: left;
    position: relative;
}
.nav-tabs__subnav .nav-tabs>li {
    padding: 0;
    float: none;
    display: inline-block;
}
.nav-tabs__subnav .nav-tabs>li>a {
    text-indent: 0;
    font-size:14px;
    padding: 0 10px;
    border-right-color: #324220;
    padding-left: 35px;
}
.nav-tabs__subnav.nav-tabs__subnav .nav-tabs>li.is-active>a, .nav-tabs__subnav .nav-tabs>li>a:hover {
    background: #324220;
    color: #fff;
}
.nav-tabs__subnav .nav-tabs>li>a:before, .nav-tabs__subnav .nav-tabs>li.active-clone a:before {
    content: none;
}
.subnav-toggle {
    position: absolute;
    right: 32px;
    top: 13px;
    font-size: 22px;
    text-indent: 0;
    z-index: 100;
}
.subnav-toggle:before {
    transition: 250ms ease-in-out;
}
.subnav-toggle.is-open:before {
    transform: rotate(45deg);
    display: block;
}
@media screen and (min-width: 768px){
    .nav-tabs__subnav {
        left: 0;
        right: 0;
        background: var(--color-lightgreen);
        padding: 15px 0;
        display: block;
    }
    .nav-tabs__subnav ul.nav-tabs {
        padding: 0 10px;
        text-align: center;
    }
    .nav-tabs__subnav .nav-tabs>li>a {
        padding-left:10px;
    }
}
@media(max-width: 767px) {
    .product-tab {
        background-color:transparent;
        height:50px;
    }
    .product-tab .nav-tabs {
        position: absolute;
        width: 100%;
        margin-left:0px;
    }
    .product-tab .nav-tabs > li {
        display:none;
        padding: 0px;
    }
    .product-tab .nav-tabs > li.active a {
        color:#336a47;
    }
    .product-tab .nav-tabs > li.active-clone {
        display:block;
        width:100%;
    }
    .product-tab .container {
        padding:0px;
    }
    .product-tab .nav-tabs>li  {
        width:100%;
        background-color:#dae8a1;
        z-index:1;
        margin-bottom:0px;
    }
    .product-tab .nav-tabs>li>a{
        height:50px;
        line-height:50px;
        border-bottom: 1px solid #a2c614;
        z-index:2;
        margin-bottom: 0;
        text-align: left;
    }
    .product-tab .nav-tabs>li.active-clone {
        background-color:#a2c614;
    }
    .nav-tabs>li.active-clone a:before {
        border-width: 10px 15px 0;
        z-index:1;
        margin-top:0px;
    }
    .product-tab .nav-tabs>li.active-clone:after {
        content: "\E013";
        font-family: iconfont;
        position:absolute;
        font-size:25px;
        color: var(--color-darkgreen);
        width: 75px;
        text-align: center;
        top: 0;
        line-height: 50px;
        right: 0;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
/*    .product-tab .open-tab>li.active-clone:after {
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }*/
    .nav-tabs__subnav.nav-tabs__subnav .nav-tabs>li>a {
        height: auto;
        line-height: 24px;
        min-height: 50px;
        padding: 12px 10px 12px 35px;
    }
}



/** ------- ProductSites ALLGEMEINES --------------------------------------------------------------------------------------------- **/
/* ---- Product Info ----*/
.product-info-wrapper {
    padding: 40px 0px;
}
.content-teaser-wrapper__two-col .product-info {
    padding:0px;
}
.content-teaser-wrapper__two-col .product-info h3 {
    margin:0px;
}
.content-teaser-wrapper__two-col .product-info .subline {
    margin-bottom:20px;
    color:var(--color-darkgreen);
    font-size:16px;
}
.content-teaser-wrapper__two-col .product-info .subline a {
    color:var(--color-lightgreen);
}
.facts {
    border-bottom: 1px solid #b6b3ad;
    border-right: none;
    vertical-align: middle;
    text-align: center;
    line-height: 1.42857143;
    padding: 10px 15px;
    margin: 0;
}
.facts.row .col {
    border-right: none;
}
@media screen and (min-width: 768px) {
    .content-teaser-wrapper__two-col .teaser .img-responsive {
        max-width: 80%;
    }
}


/* ---- Product Tabbing ----*/

.map-tab .nav-tabs {
    background-color:#ecf4d0;
    box-shadow:2px 2px 10px #c6c6c6;
}
.map-tab .nav-tabs > li > a {
    height:40px;
    font-size:14px;
    line-height:20px;
    color: var(--color-darkgreen);
    font-family: var(--font-bold);
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

@media screen and (min-width: 768px) {
    .map-tab .nav-tabs > li > a {
        font-size:20px;
    }
}
.tab-image {
    max-height: 360px;
    margin: 0px auto;
    padding: 15px;
}

/** ------- Ingredients ------------------------------------------------------------------------------------------------- **/
.ingredients-cont {
    display: block;
    position: relative;
}
.ingredients .ingredient {
    display: block;
}
@media screen and (min-width: 768px) {
    .ingredients {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .ingredients li.ingredient {
        font-size: 1.2em;
        position: absolute;
        width: 172px;
        padding: 0;
        text-indent: 0;
    }
    .ingredients .ingredient:before {
        content: '';
        position: absolute;
        background: var(--color-lightgreen);
        margin-top: -1px;
        top: 50%;
        right: -110px;
        height: 2px;
        width: 100px;
    }
    /* vertical alignment */
    .ingredients .ingredient:nth-child(1),
    .ingredients .ingredient:nth-child(2) { top: 10%; }
    .ingredients .ingredient:nth-child(3),
    .ingredients .ingredient:nth-child(4) { top: 35%; }
    .ingredients .ingredient:nth-child(5),
    .ingredients .ingredient:nth-child(6) { top: 65%; }
    .ingredients .ingredient:nth-child(7),
    .ingredients .ingredient:nth-child(8) { top: 90%; }
    /* horizontal alignment */
    .ingredients .ingredient:nth-child(1),
    .ingredients .ingredient:nth-child(7) { left: 7%; text-align: right; }
    .ingredients .ingredient:nth-child(3),
    .ingredients .ingredient:nth-child(5) { left: 4%;  text-align: right; }
    .ingredients .ingredient:nth-child(2),
    .ingredients .ingredient:nth-child(8) { right: 7%; }
    .ingredients .ingredient:nth-child(4),
    .ingredients .ingredient:nth-child(6) { right: 4%; }

    .ingredients .ingredient:nth-child(1):before,
    .ingredients .ingredient:nth-child(3):before,
    .ingredients .ingredient:nth-child(5):before,
    .ingredients .ingredient:nth-child(7):before {
        transform-origin: 0 1px;
    }
    .ingredients .ingredient:nth-child(2):before,
    .ingredients .ingredient:nth-child(4):before,
    .ingredients .ingredient:nth-child(6):before,
    .ingredients .ingredient:nth-child(8):before {
        right: auto;
        left: -110px;
        transform-origin: 100px 1px;
    }
    .ingredients .ingredient:nth-child(1):before,
    .ingredients .ingredient:nth-child(8):before { transform: rotateZ(25deg); }
    .ingredients .ingredient:nth-child(3):before,
    .ingredients .ingredient:nth-child(6):before { transform: rotateZ(10deg); }
    .ingredients .ingredient:nth-child(4):before,
    .ingredients .ingredient:nth-child(5):before { transform: rotateZ(-10deg); }
    .ingredients .ingredient:nth-child(2):before,
    .ingredients .ingredient:nth-child(7):before { transform: rotateZ(-25deg); }
}



/** ------- Three-Images-Slider ------------------------------------------------------------------------------------- **/
.gallery-three-images .slick-slide {
    height: auto;
}

/** ------- Text-Slider --------------------------------------------------------------------------------------------- **/
.text-slider__arrow-left, .text-slider__arow-right {
    font-size: 80px;
    position: absolute;
    top: 50%;
    margin-top: -30px;
    z-index: 2;
    vertical-align: middle;
    cursor: pointer;
    color:var(--color-lightgreen);
}
.text-slider__arrow-left {
    left:-100px;
}
.text-slider__arow-right {
    right:-100px;
}

.text-slider__inner h3 {
    margin-bottom:40px;
}

.text-slider .slick-disabled {
    color: rgba(196, 196, 196, 0.51);
}

@media(max-width: 767px) {

    .text-slider .text-slider__inner{
        padding:15px 40px;
    }
    .text-slider__arrow-left {
         left:10px;
        font-size:40px;

    }
    .text-slider__arow-right {
        right:10px;
        font-size:40px;
    }

}


/** ------- Img with Badge ------------------------------------------------------------------------------------------------- **/

.img-with-badge img {
    width:100%;
}

.img-with-badge {
    position:relative;
}


/** ------- Downloads ------------------------------------------------------------------------------------------------- **/
.center-item--vertical {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    align-items: center;
}

.center-img {
    margin:0 auto;

}


/** ------- Pressebilder ------------------------------------------------------------------------------------------------- **/

.js-popup-styled:focus {
    border:none;
}

.popup-bg {
    background-color:#fff;
    height:800px;
    padding:60px;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-iframe-holder .mfp-content {
    height: 921px;
}
.mfp-iframe-holder .mfp-content {
    max-width:1000px;
}
.mfp-iframe-holder .mfp-close {
    top: 5px;
    color: black;
    content:'';
    color:#fff;
    right: 15px;
}
.mfp-iframe-holder .mfp-close:before {
    content: "\E010";
    font-family: iconfont;
    position:absolute;
    top:0;
    right:10px;
    margin-right:-15px;
    font-size:30px;
    color:var(--color-lightgreen);
}

/** ------- Grafik mit Text ------------------------------------------------------------------------------------------------- **/
.graphic--with-text .big {
    color: var(--color-darkgreen);
    text-transform: uppercase;
    line-height:25px;
}

.graphic-text {
    font-size:16px;
}

.graphic--with-text img {
    padding:20px;
    margin:0 auto;
}
.graphic-text--small img {
    padding:10px 0px;
}
.graphic--with-text .col {
}
.graphic-text--small .big {
    padding-top: 90px;
}
.graphic-text--small .row >.col-sm-6 {
    height:130px;
}
.graphic-text--small .graphic-arrow {
    margin-top:105px;
}
.graphic-text--small {
    margin-bottom:20px;
}
.graphic-text--small .graphic-text {
    margin-top:10px;

}

@media(max-width: 767px){
    .embed-responsive-111.embed-responsive-change {
        padding-bottom:38%;
    }
    .graphic--with-text .big {
        font-size:25px;
    }
    .graphic-text {
        text-align: center;
        padding: 0px 15px;
        font-size:13px;
    }
    .graphic-text--small .graphic-arrow {
        margin-top:0px;
        transform: rotate(90deg);
        width:25px;
    }
    .graphic-text--small .big {
        text-align:center;
        padding-top:20px;
        padding-bottom:20px;
    }
    .graphic-text--small .embed-responsive-111.embed-responsive-change {
        padding-bottom:50%;
    }
    .graphic-text .big {
        margin-bottom: 20px;
    }
    .graphic--with-text img {
        max-height:100px;
        padding:0;
    }
    .graphic-text--small img {
        padding:0;
        max-height:57px;
    }
    .graphic-text--small .graphic-text {
        margin-top:0;
        padding:0px;
    }
    .graphic-text--small {
        width:50%;
        float:left;
        margin-right:0;
        margin-left:0;
        margin-bottom:0px;
    }
}

/** ------- Exports ------------------------------------------------------------------------------------------------- **/
.trade-graph {
    position: relative;
}
.trade-graph__text,
.trade-graph__source,
.trade-graph__exports,
.trade-graph__imports {
    position: absolute;
    width: 180px;
}
.trade-graph__text    { top: 43%;   left: 43%; text-align: center; }
.trade-graph__exports { top:  7%;   left: 49%; color: #009d3d; }
.trade-graph__imports { top: 37%;   left: 12%; color: #009d3d; }
.trade-graph__source {
    bottom: 5%;
    left: 10%;
    width: 95%;
    color: #b6b3ad;
    text-transform: none;
}

.product-tab .nav-tabs>li>.border-fix{
    border-right: none;
}

.js-gallery-slider .slick-next, .js-gallery-slider .slick-prev {
    height: 90%;
    margin-top: 5%;
    margin-bottom: 5%;
}

@media screen and (min-width: 768px) {
    .trade-graph__text    { top: 40%;   left: 50%; }
    .trade-graph__exports { top: 13%;   left: 49%; }
    .trade-graph__imports { top: 41%;   left: 41%; }
}
@media screen and (max-width: 767px) {
    .trade-graph h6 {
        font-size: 14px;
    }
}