
input.checkbox-filter-1+label:after, input.checkbox-filter-2+label:after, input.checkbox-filter-3+label:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    left: 0;
    top: 0;
    height: 20px;
}

input.checkbox-green+label:after {
    background-color: #009d3d;
}
input.checkbox-orange+label:after {
    background-color: #ffb700;
}
input.checkbox-darkorange+label:after {
    background-color: #ff6200;
}
input.checkbox-yellow+label:after {
    background-color: #ffe000;
}
input.checkbox-darkgreen+label:after {
    background-color: var(--color-darkgreen);
}

input.checkbox-filter-1+label, input.checkbox-filter-2+label, input.checkbox-filter-3+label {
    padding-left:10px;
}
.calendar__header .selectbox-wrapper .btn {
    height: 40px;
    line-height: 20px;
    margin-top: 5px;
}
.calendar__header .checkbox-wrapper {
    display:block;
    padding:10px 0px;
}

.checkbox-wrapper .form-group + .form-group {
    padding-top:0px;
}
.calendar__header {
    font-size:16px;
}
.calendar__header strong {
    font-family: var(--font-bold);
    margin-bottom:10px;
}
.calendar__header .checkbox-wrapper .input-group {
    float:left;
    padding-right:25px;
}
.calendar__body {
    margin:30px 0px;
    overflow:hidden;
    position:relative;
}

.calendar__body--months {
    background-color: var(--color-lightgreen);
    font-family: var(--font-bold);
    text-align: center;
    height:40px;
    line-height:40px;
    font-size:16px;
}


.calendar__product{
    background-color:#f3eee5;
    font-family: var(--font-bold);
    font-size:16px;
    height:60px;
    padding:5px;
    border-right:1px solid #b6b3ad;
}

.calendar__product .icon-rezept {
    text-align: right;
    color: #b6b3ad;
    float: right;
    line-height: 50px;
    margin-right: 10px;
}

.calendar__body .line-top, .calendar__body .line-middle, .calendar__body .line-bottom {
    position:absolute;
    top:0;
    left:0;
    height: 13px;
    margin-top:5px;
    z-index:1;
}
.calendar__body .line-middle {
    top:18px;
}
.calendar__body .line-bottom {
    top:36px;
}

.calendar__body  .line-top.active {
    background-color: #009d3d;
}

.calendar__body .line-middle.active {
    background-color: #ffe000;
}
.calendar__body .line-bottom.active {
    background-color: #004519;
}

.calendar__body .filter-3 .line-bottom.active,
.calendar__body .filter-1 .line-top.active,
.calendar__body .filter-2 .line-middle.active,
.calendar__body .line-top, .calendar__body .line-middle, .calendar__body .line-bottom
{
    -webkit-transition: background 0.3s ease-in;
    -moz-transition: background 0.3s ease-in;
    -ms-transition: background 0.3s ease-in;
    -o-transition: background 0.3s ease-in;
    transition: background 0.3s ease-in;
}

.calendar__body--months .col-xs-1:after {
    content: '';
    width: 1px;
    background-color:#b6b3ad;
    position: absolute;
    left: 0;
    height: 10000px;
    z-index: -1;
}

.calendar__product--img {
    padding-right:10px;
    height: 50px;
}


.calendar__product-wrapper, .calendar__product-wrapper--mobile {
    border-left: 1px solid #b6b3ad;
    border-right:1px solid #b6b3ad;
    border-top:1px solid #b6b3ad;
}
.calendar__product-wrapper:last-child, .calendar-wrapper--mobile:last-child {
    border-bottom:1px solid #b6b3ad;
}
.calendar__product-wrapper .col-xs-1 {
    height:60px;
}

.calendar-wrapper .hover-month {
    background-color: rgba(218, 232, 161, 0.44);
}

.calendar-wrapper .actual-month:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 63px;
    height: 100%;
    background-color: #dae8a1;
    z-index: -1;
    bottom: 0;
    height: 10000px;
    content: '';
}

.calendar-wrapper .actual-month {
    background-color: #dae8a1;
}

.calendar__body--products .active-month {
    z-index: -1;
}



/** ----- Anpassung Sortenkalender ---- */
.calendar-wrapper__sorten .calendar__body--months {
    background-color:#ff8c00;
}
.calendar-wrapper__sorten .calendar__body .line-top.active {
    background-color:#ffb700;
}
.calendar-wrapper__sorten .calendar__body .line-middle.active {
    background-color:#ff6200;
}
.calendar-wrapper__sorten .hover-month {
    background-color: rgba(245, 212, 94, 0.36);
}
.calendar-wrapper__sorten .calendar__product--name {
    padding:10px;
    line-height:50px;
}
.calendar-wrapper__sorten .calendar__body .line-top,
.calendar-wrapper__sorten .calendar__body .line-middle {
    margin-top:18px;
}
.calendar-wrapper__sorten .calendar__text p{
    color:#b6b3ad;
}
.calendar-wrapper__sorten .actual-month:before {
    background-color: #ffe299;
}
.calendar-wrapper__sorten .actual-month {
    background-color: #ffe299;
}




/* --------- Mobile -------- */
.calendar__filter {
    background-color: var(--color-lightgreen);
    width:100%;
    height:50px;
    line-height:50px;
    display:block;
    padding:0px 20px;
    color: #004220;
    font-family: var(--font-bold);
    font-size:18px;
    text-transform: uppercase;
    position:relative;
}

.calendar__filter:after {
    content: "\E006";
    font-family: iconfont;
    position: absolute;
    font-size: 25px;
    color: #004220;
    width: 75px;
    text-align: center;
    top: 0;
    line-height: 50px;
    right: 0;
    transition: all .2s ease;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.calendar__filter.collapsed:after {
    transition: all .2s ease;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

@media(max-width: 767px) {
    .calendar__header {
        background-color: #ececec;
    }

    .calendar__body {
        margin:0;
    }
    .calendar__product {
        border:none;
        position:relative;
    }
    .filter-container {
        padding:10px;
    }
    .calendar__product-inner{
        padding:10px;
        background-color:#fff;
    }
    .calendar__product--text-label {
        font-family: var(--font-bold);
        font-size:16px;
    }
    .calendar__product--text-label + p {
        padding-bottom:10px;
        border-bottom:1px solid rgba(128, 128, 128, 0.16);
    }
    .calendar__product:before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        margin-left: -15px;
        border-style: solid;
        border-color: #f3eee5 transparent;
        border-width: 10px 15px 0;
        z-index: 1;
        width: 0;
        height: 0;
        display: block;
        transition: all .15s ease;
    }
    .calendar__product.collapsed:before {
        border-width: 0px 15px;
        z-index:0;
        bottom:0;
        transition: all .15s ease;

    }
    .filter-container .has-select label {
        padding-top:10px;
    }
    .calendar__product:after {
        position:absolute;
        font-family: iconfont;
        right:30px;
        top:50%;
        margin-top:-10px;
        content: "\E002";
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
        -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
        transition: all .15s ease;
    }
    .calendar__product.collapsed:after {
        position:absolute;
        font-family: iconfont;
        right:30px;
        top:50%;
        margin-top:-10px;
        content: "\E002";
        transition: all .15s ease;
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

}