/** ------- FOOTER ----------------------------------------------------------------------------------------------------- **/

#footer-wrapper {
    font-size:16px;
    overflow: hidden;
}
#footer-wrapper .row {
    margin: 0;
}

.footer-top .phone a {
    text-decoration: none;
    color:#fff;
}
.footer-top {
    background-color: var(--color-darkgreen);
    color:#fff;
    padding: 25px 0px;
}
.footer-bottom {
    background-color: var(--color-lightgreen);
    padding: 25px 0px;
    font-family: var(--font-default);
}
.footer-bottom a {
    padding-right: 50px;
    color:#000;
}
.footer-top h4 {
    font-size:24px;
    text-transform: uppercase;
    font-family: var(--font-bold);
}
.social-icons span {
    color: var(--color-lightgreen);
    font-size:44px;
}
.social-icons .icon-whatsapp {
    font-size:41px;
}
.social-icons span:hover {
    color:#fff;
}
.form-group.email-wrapper {
    margin-top:10px;
}
.social-icons {
    margin-top:10px;
}
.email-wrapper .form-control {
    border-radius:0px;
}
.email-wrapper {
    position:relative;
}
.email-wrapper a{
    color: var(--color-darkgreen);
    float: right;
    top: 9px;
    position: absolute;
    right: 7px;
}
.footer-top .contact {
    padding-top:10px;
}

#footer-wrapper .footer-bottom .container, #footer-wrapper .footer-top .container-footer {
    width: auto;
    padding: 0px;
}
@media (max-width: 767px) {
    footer .address {
        text-align:center;
    }
    #footer-wrapper .footer-btn__mobile {
        background-color:#fff;
        color:var(--color-darkgreen);
        padding: 15px;
        width:45%;
        float:left;
        font-size:13px;
        text-transform: none;
        font-family: var(--font-bold);
        margin: 10px 5px;
        text-align: center;
    }
    #footer-wrapper .footer-btn__mobile a {
        color: var(--color-darkgreen);
        font-size:13px;
        text-decoration: none;
    }
    .social-icons {
        text-align: center;
        padding: 25px 0px;
    }
    .footer-bottom {
        text-align: center;
    }
    .footer-bottom a {
        padding-right:0px;
    }
    .footer-bottom a+a {
        margin-left:15px;
    }
    .footer-bottom .elements {
        text-align: center;
        margin-top:15px;
        float:none !important;
    }
    .footer-bottom .col {
        padding:10px 0px;
    }
}

/*------------ footer-logos -----------------*/
.footer-logos {
    padding: 25px 15px;
    text-align: center;
}

.footer-logos__item {
    display: inline-block;
}
.footer-logos__item-img {
    height: 70px;
    margin: 0 25px 10px 25px;

}

.footer-logos__title {
    margin-bottom: 15px;
}
.footer-social__link {
    min-height: 50px;
    padding-right: 15px;
}
.footer-social__link img{
    display: inline!important;
}
.social-icons {
    display: flex;
    align-items: center;
}