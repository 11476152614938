@media screen and (max-width: 767px) {
    .glossary-wrapper__selector {
        position: relative;
        padding-bottom: 25px;
    }
    .glossary-wrapper__selector:before,
    .glossary-wrapper__selector:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 40px;
        background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* IE10+ */
        background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C */
        z-index: 10;
        pointer-events: none;
    }
    .glossary-wrapper__selector:after {
        left: auto;
        right: 0;
        background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* IE10+ */
        background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C */
    }
}
.glossary--letters {
    background-color:#f3eee5;
    width:100%;
    height:50px;
    line-height:50px;
    list-style:none;
    padding-left: 40px !important;
    padding-right: 40px !important;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.glossary--letters .glossary--letters-item {
    display: inline-block;
    width: 38px;
    float: none;
    text-align:center;
    font-size:18px;
}
.glossary--letters .glossary--letters-item a{
    color:var(--color-darkgreen);
    line-height:52px;
}
.glossary--letters-item.active {
    position:relative;
}
.glossary--letters-item.active:before {
    content: '';
    background-color: var(--color-lightgreen);
    width: 100%;
    height: 55px;
    display: block;
    color:#fff;
    top: -3px;
    position: absolute;
    z-index: 0;
    left:0;
}
@media screen and (min-width: 1200px) {
    .glossary--letters {
        padding-left: 0 !important;;
        padding-right: 0 !important;;
        overflow-x: visible;
        overflow-y: visible;
    }
    .glossary--letters .glossary--letters-item {
        display: block;
        float:left;
    }
}

.glossary--letters-item.active:after {
    content: '';
    position: absolute;
    top: 52px;
    left: 50%;
    margin-left: -18px;
    border-style: solid;
    border-color: #a2c614 transparent;
    border-width: 10px 18px 0;
    width: 0;
    height: 0;
    display: block;
    z-index: 0;
    transition: all .15s ease;
}
.glossary--letters-item.active a {
    position: relative;
    color:#fff;
}

.glossary--letters-item.disabled a {
    color:#99b5a3;
    cursor: default;

}
.nav-tabs .glossary--letters-item.disabled {
    background-color:transparent;
    cursor: default;

}
.nav-tabs .glossary--letters-item.disabled:before,
.nav-tabs .glossary--letters-item.disabled a:before,
.glossary--letters-item.disabled:after {
    display:none;
}