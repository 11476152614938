.teaser-portal-content {
    height: 100%;
    position: relative;
    color: var(--color-darkgreen);
}

@media screen and (max-width: 767px) {
    .teaser-portal-content__content {
        font-size: 14px;
    }
    .teaser-portal-content__headline {
        font-size: 20px;
    }
}

.teaser-portal-content__badge {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    max-width: 63px;
}

.teaser-portal-content__headline {
    font-size: 25px;
    font-family: var(--font-bold);
    line-height: 1;
}

.teaser-portal-content__content{
    color: #211D1B;
    font-size: 16px;
}
