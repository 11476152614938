/** ------- PORTAL- TEASER --------------------------------------------------------------------------------------------- **/

.color-light .teaser__subline, .color-light .teaser__headline, .color-light .teaser__text {
    color:white;
}
.color-dark .teaser__subline, .color-dark .teaser__headline {
    color:var(--color-darkgreen);
}
.color-dark .teaser__text {
    color:#1f1d1a;
}

.portal-teaser-wrapper__three-col .row, .portal-teaser-wrapper__two-col .row{
    margin:0;
}
.portal-teaser-wrapper__three-col .col, .portal-teaser-wrapper__two-col .col {
    padding:0;
}
.teaser__inner {
    position:absolute;
    top:0;
    left:0;
    right:0;
    margin: 45px;
}
.teaser__headline {
    margin-top:0px;
    text-transform: uppercase;
    font-family: var(--font-extrabold);
    font-size: 60px;
}

.teaser__subline {
    text-transform: uppercase;
    font-size: 18px;
    font-family: var(--font-extrabold);
    margin-bottom:0px;
}
.teaser__text {
    width: 50%;
    font-size:18px;
    padding-bottom:20px;
}
.portal-teaser-wrapper__three-col .teaser__text {
    width: 70%;
    padding-bottom: 10px;
}
.portal-teaser-wrapper__three-col .teaser__inner {
    margin: 10px 20px;
}
@media screen and (min-width: 1200px) and (max-width: 1399px){
    .portal-teaser-wrapper__three-col .teaser__inner {
        margin: 25px;
    }
}
@media screen and (min-width: 1400px){
    .portal-teaser-wrapper__three-col .teaser__text {
        width: 60%;
    }
    .portal-teaser-wrapper__three-col .teaser__inner {
        margin: 45px;
    }
}
.triangle-badge {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 135px 135px 0;
    border-color: transparent #ffe000 transparent transparent;
    position:absolute;
    right:0;
    z-index:1;
}
.triangle-badge__text {
    text-transform: uppercase;
    top: 22px;
    line-height:20px;
    left: 50px;
    text-align: center;
    position: absolute;
    font-family: var(--font-extrabold);
    font-size:20px;
    color: var(--color-darkgreen);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.triangle-badge__text--small {
    top:22px;
    left:61px;
}
@media (max-width: 767px) {
    .teaser__inner {
        margin:25px;
        /*width:100%;*/
    }
    .portal-teaser-wrapper .teaser__text {
        width:60%;
        font-size:13px;
        padding-bottom:0px;
    }
    .portal-teaser-wrapper .color-dark .teaser__text {
        text-shadow:0px 2px 3px rgba(255,255,255,.9)
    }
    .portal-teaser-wrapper .color-light .teaser__text {
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
    }
    .teaser__subline {
        font-size:14px;
        line-height:14px;
        text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);

    }
    .teaser__headline {
        font-size:33px;
        line-height:40px;
        margin-bottom:0px;
        text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);

    }
    .triangle-badge {
        border-width: 0 100px 100px 0;
    }
    .triangle-badge__text {
        font-size:15px;
        top:15px;
        left:40px;
    }
}


/** ------- CONTENT TEASER --------------------------------------------------------------------------------------------- **/
.goToLink:hover a {
    text-decoration: underline;
}
.goToLink:hover a.btn {
    text-decoration: none;
}
.content-teaser-wrapper a {
    color:#009d3d;
    font-family: var(--font-bold);
}


/** ------- CONTENT TEASER-HORIZONTAL ---------------------------------------------------------------------------------- **/
.content-teaser-wrapper__horizontal .teaser {
    padding:20px 0px;
    border-top: 2px solid #f3eee5;
    border-bottom:2px solid #f3eee5;
}

.content-teaser-wrapper__horizontal .teaser + .teaser {
    border-top:none;
}

@media(min-width: 768px) {
    .content-teaser-wrapper__horizontal .display-table {
        width:100%;
    }
}

/** ------- CONTENT TEASER-2col ---------------------------------------------------------------------------------------- **/
.content-teaser-wrapper__two-col .col:first-child {
    border-right:2px solid #f3eee5;
}
.content-teaser-wrapper__two-col .teaser__body {
    padding:10px;
}

@media(max-width: 767px) {
    .content-teaser-wrapper .teaser {
        padding: 20px 0;
        border-bottom: 2px solid #f3eee5;
    }
}


/** ------- CONTENT TEASER-2col ---------------------------------------------------------------------------------------- **/

.content-teaser-wrapper__three-col h5 {
    font-family: var(--font-light);
}
.content-teaser-wrapper__three-col .teaser__body {
    padding:10px;
}
.content-teaser-wrapper__three-col+.content-teaser-wrapper__three-col {
    margin-top:50px;
}
.content-teaser-wrapper__three-col .col {
    border-right: 2px solid #f3eee5;
}
@media(min-width: 768px) {
    .content-teaser-wrapper__three-col .col:last-child {
        border-right:none;
    }
}
/** ------- CONTENT Contact -------------------------------------------------------------------------------------------- **/
.teaser__body-contact {
    font-size: 16px;
}


/** ------- CONTENT TEASER REZEPTE ------------------------------------------------------------------------------------- **/
.teaser__white-bg {
    background-color:#fff;
}
.teaser__white-bg .btn {
    margin:15px 0px;
}

.content-teaser-wrapper__three-col .teaser__white-bg .col:nth-child(2) {
    border-left:2px solid #b6b3ad;
    border-right:2px solid #b6b3ad;
}

@media(max-width: 767px) {
    .teaser__body {
        padding:10px;
    }
}

/** ------- News Teaser ------------------------------------------------------------------------------------------------ **/
.teaser__body--date {
    font-family: var(--font-bold);
    font-size:16px;
    color:#b6b3ad;
}

/** ------- Lebensmittel Kategorien ------------------------------------------------------------------------------------ **/

.content-teaser-wrapper__white {
    background-color:#fff;
}
.content-teaser-wrapper__white > .row >.col {
    padding:20px;
    background-color:#fff;
    border: 1px solid #f3eee5;
}
.content-teaser-wrapper__white > .row >.col + .col {
    border-left:none;
}

.content-teaser-wrapper__white .teaser__body {
    padding-top:5px;
}
.content-teaser-wrapper__white .teaser__body h6 {
    margin-bottom:0px;
}
.content-teaser-wrapper__white .teaser__body h6 a {
    color: var(--color-darkgreen);
    font-family: var(--font-extrabold);
}
.headline--found-products {
    line-height:40px;
    color:var(--color-darkgreen);
    margin:0;
    font-family: var(--font-light);
}
@media(max-width: 767px) {
    .content-teaser-wrapper__white .teaser {
        border-bottom:0px;
    }
}
/** ------- Teaser-Wrapper Grey ------------------------------------------------------------------------------------ **/
.teaser-wrapper--grey {
    background-color: #f3eee5;
}

.teaser-wrapper--grey .teaser__body {
    padding: 10px;
}
@media screen and (min-width: 768px) {
    .teaser-wrapper--grey .teaser__body {
        padding: 25px;
    }
}

/** ------- Lebensmittel Übersicht ------------------------------------------------------------------------------------ **/

.teaser-wrapper__two-col--lm .teaser {
    padding-left:0;
    padding-right:0;
}
.teaser-wrapper__two-col--lm .row {
    margin-left:0;
    margin-right:0;
}
.teaser-wrapper__two-col--lm .teaser__headline {
    margin-bottom:10px;
}
.teaser-wrapper__two-col--lm .teaser__headline {
    font-family: var(--font-extrabold);
}
.teaser-wrapper__two-col--lm .teaser__text{
    width:60%;
}
.teaser--special {
    background-size: cover;
    min-height:532px;
}
.teaser--special .teaser__inner {
    position:relative;
}
@media (max-width: 767px) {
    .teaser-wrapper__two-col--lm .teaser__inner {
        padding:25px;
        margin:0;
    }
    .teaser--special {
        height:auto;
        min-height:inherit;
    }
}