.img-full-width {
    width: 100%;
}

/* row--same-height */
@media screen and (min-width: 768px) {
    .row--same-height__item {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }

    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--same-height {
        display: flex;
        flex-direction: row;
        /*align-items: stretch;*/
        /*justify-content: space-between;*/
        overflow: hidden;
        flex-wrap: wrap;
    }

    .row--same-height:before,
    .row--same-height:after {
        display: none;
    }

    .row--same-height > .col {
        display: flex;
        flex-direction: column;
        height: auto;
        min-width: 0;
    }

    .row--same-height__item--fixed-height {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
    }

    .row--same-height__item__variable-height {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
    }

    .row--same-height--center {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .row--same-height__item--last {
        order: 99;
    }

}

.row.row--gutter-width-10 {
    margin-left: -5px;
    margin-right: -5px;
}

.row.row--gutter-width-10 > .col {
    padding-left: 5px;
    padding-right: 5px;
}

.row.row--gutter-width-32 {
    margin-left: -16px;
    margin-right: -16px;
}

.row.row--gutter-width-32 > .col {
    padding-left: 16px;
    padding-right: 16px;
}

@media screen and (max-width: 767px){
    .row.row--gutter-width-32 {
        margin-left: -7px;
        margin-right: -7px;
    }

    .row.row--gutter-width-32 > .col {
        padding-left: 7px;
        padding-right: 7px;
    }

}

.centered {
    margin: 0 auto;
}

.font-extrabold {
    font-family: var(--font-extrabold) !important;
}

.w-100 {
    width: 100%;
}

.row--inline-block {
    font-size: 0;
    text-align: center;
}

.row--inline-block > .col {
    display: inline-block;
    float: none;
    font-size: 18px;
    vertical-align: middle;
}

.row--no-gutter {
    margin: 0;
}

.row--no-gutter .col {
    padding: 0;
}

.d-inline-block {
    display: inline-block;
}

.row-centered {
    text-align:center;
    display: block!important;
}

.col-centered {
    display:inline-block!important;
    float:none;
    text-align:left;
}
@media screen and (max-width:767px){
    .col-centered {
        margin-top: -5px;
        margin-right: -4px;
    }
}

.position-relative{
    position: relative;
}

.h-100 {
    height: 100%!important;
}
/*/!*embed responsives*!/*/
/*.embed-responsive-item img {*/
/*/!*needed for picture elements*!/*/
/*width: 100%;*/
/*height: auto;*/
/*}*/
.embed-responsive.embed-responsive-3by2 {
    padding-top: calc(2 / 3 * 100%);
}

.embed-responsive.embed-responsive-4by3 {
    padding-top: calc(3 / 4 * 100%)!important;
}

.mt0 {
    margin-top: 0!important;
}

.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.mb10 {
    margin-bottom: 10px;
}

.mt15 {
    margin-top: 15px;
}
.mt25 {
    margin-top: 25px;
}

.pb-0 {
    padding-bottom: 0;
}

.mb30 {
    margin-bottom: 30px;
}

.bg-light {
    background-color: #F5F0E8;
}

.increased-click-area {
    padding: calc(12rem/16);
    margin: calc(-12rem/16);
}

.font-bold {
    font-family: var(--font-bold)!important;
}

.no-borders{
    border: none!important;
}