.inline-nav__wrapper .nav li {
    background-color:#807a73;
    position: relative;
    height:40px;
    color:#fff;
    width:260px;
    -moz-transform: translateX(255px);
    -webkit-transform: translateX(255px);
    -o-transform: translateX(255px);
    -ms-transform: translateX(255px);
    transform: translateX(255px);
    z-index:10;
}

.inline-nav__wrapper .nav li.active,
.inline-nav__wrapper .nav li:hover {
    background-color: var(--color-lightgreen);
}

.inline-nav__wrapper:hover .nav li.active:before {
    width: 0;
    display:block;
    position:absolute;
    content:'';
    height: 0;
    margin-left:-10px;
    border-style: solid;
    border-width: 20px 10px 20px 0;
    border-color: transparent var(--color-lightgreen) transparent transparent;

}
.inline-nav__wrapper .nav li+li {
    margin-top:5px;
}
.inline-nav__wrapper .nav li a {
    color:#fff;
    font-family: var(--font-bold);
}

.inline-nav__wrapper {
    right:0;
    position:absolute;
    top:160px;
    width:275px;
    margin-right: 20px;
    overflow:hidden;
}


.inline-nav__wrapper.affix {
    position:fixed;
    z-index:10;
}
.inline-nav__wrapper .nav {
    float:right;
}


/* --- ANIMATIONS --- */
.inline-nav__wrapper {
    max-width: 300px;
    transition: max-width 0s linear 200ms;
}
.inline-nav__wrapper:hover .nav li {
    transform: translateX(0px);
    cursor: pointer;
    width: 260px;
    height: 40px;
}