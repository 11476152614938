/* --- FAQ - BOX --- */
.faq {
    position: relative;
    border: 1px solid #b6b3ad;
    border-top-width: 0;
    overflow: hidden;
    cursor: pointer;
}
.row--faq:first-child .col:first-child .faq {
    border-top-width: 1px;
}
.row--faq .col:nth-child(even) .faq {
    background: #f3eee5;
}
.faq.active {
    border-bottom-color: #c7dd72;
}
.faq__box {
    padding: 15px 70px 15px 15px;
}



.faq .faq__btn {
    position: absolute;
    font-size: 20px;
    right: 20px;
    bottom: 50%;
    height: 20px;
    line-height: 20px;
    margin-bottom: -10px;
    transition: all 150ms ease-out;
}
.faq h6 {
    margin: 0;
}
@media screen and (min-width: 768px) {
    .faq {
        border: 1px solid #b6b3ad;
        border-top-width: 0;
        border-left-width: 0;
        padding: 0 0 100% 0;
    }
    .row--faq:first-child .faq {
        border-top-width: 1px;
    }
    .row--faq .col:first-child .faq {
        border-left-width: 1px;
    }
    .row--faq:nth-child(odd) .col:nth-child(even) .faq,
    .row--faq:nth-child(even) .col:nth-child(odd) .faq {
        background: #f3eee5;
    }
    .row--faq:nth-child(even) .col:nth-child(even) .faq {
        background: none;
    }
    .faq__box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px 20px 70px 20px;
    }
    .faq h6 {
        line-height: 1.4;
    }
    .faq .faq__btn {
        font-size: 25px;
        bottom: 35px;
        tranistion: transform 150ms ease-out;
    }
    .faq:hover .faq__btn {
        transform: scale(1.2);
    }
}


/* --- CLOSE - OVERLAY --- */
.faq__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #c7dd72;
    text-align: center;
    visibility: hidden;

    opacity: 0;
    transform: translateY(30px);
    transition: all 150ms ease;
}
.faq.active .faq__overlay {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.faq__overlay .icon {
    font-size: 24px;
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 30px;
    top: 50%;
    left: 50%;
    margin-top: -15px;

    transform: scale(1);
    transition: transform 150ms ease-out;
}
.faq__overlay:hover .icon {
    transform: scale(0.8);
}
@media screen and (min-width: 768px) {
    .faq__overlay .icon {
        font-size: 48px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        margin-top: -30px;
        margin-left: -30px;
    }
}

/* --- FAQ - CONTENT --- */
.faq__cont {
    background: #c7dd72;
    position: relative;
    display: block;
    clear: both;
    padding: 5px 20px 20px 20px;
    border: 1px solid #b6b3ad;
    border-top: none;
}
.faq__cont .icon-arrow-slider {
    display: none;
    position: absolute;
    left: 30px;
    width: 25px;
    top: 50%;
    margin-top: -20px;
    height: 40px;
    line-height: 40px;
    transition: all 150ms ease-out;
}
.faq__cont .icon-arrow-slider.next {
    right: 30px;
    text-decoration: none;
    left: auto;
}
.faq__cont .icon-arrow-slider:hover,
.faq__cont .icon-arrow-slider:focus {
    text-decoration: none !important;
    color: #004220;
}
.faq__cont .icon-arrow-slider.prev:hover {
    transform: translateX(-15px) scaleX(-1);
    text-decoration: none;
    color: var(--color-darkgreen);
}
.faq__cont .icon-arrow-slider.next:hover {
    transform: translateX(15px);
    text-decoration: none;
    color: var(--color-darkgreen);
}
.faq__cont h6 {
    margin-top: 0;
    font-size: 13px;
}
@media screen and (min-width: 768px) {
    .faq__cont {
        padding: 55px 185px;
    }
    .faq__cont h6 {
        font-size: 26px;
    }
    .faq__cont .icon-arrow-slider {
        display: block;
        font-size: 75px;
        left: 60px;
        width: 50px;
        margin-top: -40px;
        height: 80px;
        line-height: 80px;
    }
    .faq__cont .icon-arrow-slider.next {
        right: 60px;
    }
}