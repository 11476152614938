/** ------- PORTAL-SLIDER ---------------------------------------------------------------------------------------------- **/

.portal-slider__arrow-left, .portal-slider__arow-right {
    font-size: 80px;
    position: absolute;
    top: 50%;
    margin-top: -30px;
    z-index: 2;
    vertical-align: middle;
    cursor: pointer;
    color: #fff;
    text-shadow: 2px 2px 30px #555555;
}

.portal-slider__arrow-left {
    left: 60px;
}

.portal-slider__arow-right {
    right: 60px;
}

.blog-slider .portal-slider__arow-right, .blog-slider .portal-slider__arrow-left {
    top: 35%;
}

.portal-teaser-wrapper__three-col .teaser__headline {
    font-size: 33px;
}

@media screen and (min-width: 1200px) {
    .blog-slider .portal-slider__arow-right, .blog-slider .portal-slider__arrow-left {
        top: 40%;
    }
}

@media screen and (min-width: 1400px) {
    .portal-teaser-wrapper__three-col .teaser__headline {
        font-size: 60px;
    }
}

/* Dots */
.portal-slider .slick-dots {
    position: absolute;
    bottom: 5%;
}

.blog-slider .slick-dots {
    bottom: auto;
    top: 25%;
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}

.slick-dots li {
    position: relative;

    display: inline-block;

    width: 15px;
    height: 15px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 15px;
    height: 15px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    line-height: 15px;

    position: absolute;
    top: 0;
    left: 0;

    width: 15px;
    height: 15px;

    content: '';
    text-align: center;

    background: white;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    opacity: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    background: var(--color-lightgreen);
    border: white 3px solid;
    box-sizing: border-box;
}

.slick-dots li:before {
    content: "";
}

/* end of dots */

.portal-slider .portal-slider__inner {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    margin: 0 auto;
    color: #fff;
    width: 100%;
    text-align: center;
}
@media screen and (min-width:768px) and (max-width:1300px){
    .portal-slider .portal-slider__inner {
        top: 15%;
        transform: translateY(0);
    }
}
@media screen and (max-width:767px){
    .portal-slider .portal-slider__inner {
        top: 20%;
        transform: translateY(0);
    }
}

.portal-slider .slick-slide img {
    width: 100%;
}

.slick-initialized .slick-slide {
    position: relative;
}

.portal-slider__headline {
    text-align: center;
}

@media screen and (max-width: 767px) {
    .portal-slider__headline {
        margin-bottom: 20px;
    }
}

.portal-slider__headline h1,
h1.portal-slider__headline {
    text-shadow: 2px 2px 30px #000;
    font-size: unset!important;
    margin-top: 0!important;
    /*font-size: 100px;
    font-family: var(--font-light);
    margin-bottom: 30px;*/
}


@media screen and (min-width:768px){
    .portal-slider__item {
        position: relative;
    }

    .portal-slider__item::before {
        position: absolute;
        inset: 0;
        content: "";
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    }
}

.portal-slider__content {
    display: block;
    max-width: 484px;
    font-family: var(--font-bold);
    text-align: center;
    margin: clamp(15px, 1vw, 25px) auto clamp(20px, 1.5vw, 40px) auto;
}
.portal-slider__content p {
    font-size: clamp(12px, 1vw, 18px);
}

.portal-slider__sub-headline,
.portal-slider__headline h2 {
    display: block;
    text-shadow: 2px 2px 30px #000;
    font-family: var(--font-creative);
    /*because of strange line positioning of the font rukola*/
    line-height: .75;
    position: relative;
    top: 0.3em;
}

.portal-slider__sub-headline--regular {
    font-family: var(--font-bold);
}
@media (min-width: 768px) {
    .portal-slider__sub-headline {
        font-size: clamp(50px, 5.5vw, 100px);
        line-height: 1;
    }
    .portal-slider__sub-headline--regular {
        font-size: clamp(35px, 3.75vw, 70px);
        line-height: 1;
    }
}

@media (max-width: 767px) {
    .portal-slider .portal-slider__arrow-left, .portal-slider .portal-slider__arow-right {
        display: none !important;
    }

    .portal-slider__headline h1 {
        font-size: 35px;
    }

    .portal-slider__sub-headline,
    .portal-slider__headline h2 {
        font-size: 47px;
        margin-top: 0px;
    }

    .portal-slider__content {
        display: none;
    }

    .portal-slider__sub-headline--regular {
        font-size: 32px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .gallery-slide-item .icon-zoom {
        display: none;
    }

    .nav-tabs__subnav .nav-tabs > li > a:hover {
        background: none;
        color: #004220;
    }

    .circle-wrapper {
        top: auto;
    }

    .product-way-item--inner .product-way-item--right {
        width: 43%;
        left: 57%;
    }

    .product-way-item--inner .product-way-item--left {
        width: 42%;
    }

    .product-way-item--inner .product-way-item--left .btn-video {
        margin-right: 0;
    }

    .inline-nav__wrapper {
        display: none;
    }

    .nav-form .form-control {
        display: block;
        width: 10em;
    }

    .search-form label {
        display: none;
    }

    .search-form .icon-search {
        left: .5em;
        line-height: normal;
        padding: 0;
        top: calc(50% - 9px);
        width: 1em;
    }

    #presse-form .styled-select:before {
        content: "";
    }
}


@media screen and (max-width: 767px) {
    .portal-slider--portal .slick-slide img {
        width: 200%;
        transform: translateX(-25%);
    }
}

.portal-slider__benefits {
    background-color: #f5f0e8;
    color: var(--color-darkgreen);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: clamp(6px, .5vw, 10px);
    font-family: var(--font-bold);
    font-size: clamp(14px, 1vw, 18px);
    text-align: center;
}

.portal-slider__benefits__btn {
    margin-left: 20px;
    padding: clamp(6px, .5vw, 12px) clamp(8px, .75vw, 15px);
    font-size: clamp(14px, 1vw, 16px);
    max-height: 32px;
    line-height: 1;
}

.portal-slider__benefits__arrow {
    display: none;
}

.portal-slider__benefits .container {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 767px) {
    .portal-slider__benefits__btn {
        display: none;
    }

    .portal-slider__benefits__arrow {
        display: inline;
        margin-left: 10px;
        font-size: 8px;
    }

    .portal-slider__benefits {
        font-size: 16px;
    }
    .portal-slider__benefits .container {
        display: block;
    }
}